import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import DatePickerInput from '../../components/DatePickerInput/DatePickerInput';
import TimePickerInput from '../../components/TimePickerInput/TimePickerInput';
import {
  bookNowQuoteService,
  getQuoteDetailsService
} from '../../services/quote.service';
import { getCityStateNames } from '../../services/geo.service';
import {
  BLANKET_WRAP,
  LTL_FREIGHT
} from '../../constants/service_type.constants';
import {
  formatPickupDeliveryDate,
  formatPickupDeliveryTime
} from '../../utils/date_conversion.util';
import { BID_TYPE } from '../../constants/bids.constants';
import { getBookingStatus } from '../../utils/quote.util';

const BookNowQuotePage = () => {
  const { QUOTE_FROM_NET, FIXED_AMOUNT } = BID_TYPE;
  const navigate = useNavigate();
  const { quoteId, bidId } = useParams();

  const [isAPICalled, setisAPICalled] = useState(false);
  const [quote, setQuote] = useState(null);
  const [isBookingAllowed, setIsBookingAllowed] = useState(false);

  const validationSchema = yup.object({
    pickupInfo: yup.array().of(
      yup.object().shape({
        pickup_address: yup.object().shape({
          company: yup.string().trim().required('Company name is required'),
          address1: yup.string().trim().required('Address Line 1 is required'),
          address2: yup.string(),
          city: yup.string().required('City is required'),
          state: yup.string().required('State is required'),
          country: yup.string().required('Country is required').default('US'),
          zip: yup.string().required('Zip code is required'),
          contactperson: yup
            .string()
            .trim()
            .required('Contact person name is required'),
          reference: yup.string(),
          contactnumber: yup
            .string()
            .matches(/^[0-9]+$/, 'Mobile number must be only digits')
            .required('Mobile number is required')
            .max(11)
            .min(10, 'Mobile number must be at least 10 digits')
        }),
        pickup_special_instructions: yup.string(),
        pickup_date: yup.string().required('Pickup Date is required'),
        pickup_time: yup.string().required('Pickup Time is required'),
        pickup_close_time: yup
          .string()
          .required('Pickup Close Time is required')
      })
    ),
    deliveryInfo: yup.array().of(
      yup.object().shape({
        delivery_address: yup.object().shape({
          company: yup.string().trim().required('Company name is required'),
          address1: yup.string().trim().required('Address Line 1 is required'),
          address2: yup.string(),
          city: yup.string().required('City is required'),
          state: yup.string().required('State is required'),
          country: yup.string().required('Country is required').default('US'),
          zip: yup.string().required('Zip code is required'),
          contactperson: yup
            .string()
            .trim()
            .required('Contact person name is required'),
          reference: yup.string(),
          contactnumber: yup
            .string()
            .matches(/^[0-9]+$/, 'Mobile number must be only digits')
            .required('Mobile number is required')
            .max(11)
            .min(10, 'Mobile number must be at least 10 digits')
        }),
        delivery_special_instructions: yup.string(),
        delivery_date: yup.string().required('Delivery Date is required'),
        delivery_time: yup.string().required('Delivery Time is required'),
        delivery_close_time: yup
          .string()
          .required('Delivery Close Time is required')
      })
    ),
    cargos: yup.array(
      yup.object({
        nmfc_code: yup.string().required('NMFC is required')
      })
    )
  });

  const {
    register,
    watch,
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isValid }
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const bookData = watch();
  async function fetchQuoteDetails() {
    try {
      const response = await getQuoteDetailsService(quoteId);

      setQuote(response.data.quote);

      const quoteData = { ...response?.data?.quote };
      const pickupQuoteData = quoteData.isMultipleQuote
        ? quoteData?.pickupInfo?.map((pick) => pick.pickup_address)
        : quoteData?.pickup_address;
      const deliveryQuoteData = quoteData.isMultipleQuote
        ? quoteData?.deliveryInfo?.map((del) => del.delivery_address)
        : quoteData?.delivery_address;
      const pickupDataAddress = quoteData.isMultipleQuote
        ? quoteData?.pickupInfo?.map((pickup) =>
            pickup?.pickup_from?.split('|')
          )
        : quoteData?.pickup_from?.split('|');
      const pickupData = quoteData.isMultipleQuote
        ? quoteData?.pickupInfo?.map((pickup) =>
            pickup?.pickup_from
              ?.split('|')
              ?.[
                Number(pickup?.pickup_from?.split('|')?.length) - 1
              ]?.split(',')
          )
        : quoteData?.pickup_from
            ?.split('|')
            ?.[
              Number(quoteData?.pickup_from?.split('|').length) - 1
            ]?.split(',');
      const deliveryDataAddress = quoteData.isMultipleQuote
        ? quoteData?.deliveryInfo?.map((del) => del?.delivery_to?.split('|'))
        : quoteData?.delivery_to?.split('|');
      const deliveryData = quoteData.isMultipleQuote
        ? quoteData?.deliveryInfo?.map((del) =>
            del?.delivery_to
              ?.split('|')
              ?.[Number(del?.delivery_to?.split('|').length) - 1]?.split(',')
          )
        : quoteData?.delivery_to
            ?.split('|')
            ?.[
              Number(quoteData?.delivery_to?.split('|').length) - 1
            ]?.split(',');

      const isPickupAddressChanged = !pickupQuoteData
        ? false
        : parseInt(pickupQuoteData?.latitude, 10) !==
            parseInt(quoteData?.pickupLatitude, 10) ||
          parseInt(pickupQuoteData?.longitude, 10) !==
            parseInt(quoteData?.pickupLongitude, 10);
      const isDeliveryAddressChanged = !deliveryQuoteData
        ? false
        : parseInt(deliveryQuoteData?.latitude, 10) !==
            parseInt(quoteData?.deliveryLatitude, 10) ||
          parseInt(deliveryQuoteData?.longitude, 10) !==
            parseInt(quoteData?.deliveryLongitude, 10);

      let pickupAddress1;
      let pickupAddress2;
      let pickupCity;
      let pickupState;
      let pickupCountry;
      let deliveryAddress1;
      let deliveryAddress2;
      let deliveryCity;
      let deliveryState;
      let deliveryCountry;

      if (!pickupData?.length || !deliveryData?.length) {
        const [pickupResponse, deliveryResponse] = await Promise.all([
          getCityStateNames(response.data.quote.pickup_zip_code),
          getCityStateNames(response.data.quote.delivery_zip_code)
        ]);
        pickupCity = pickupQuoteData?.city || pickupResponse.cityName;
        pickupState = pickupQuoteData?.state || pickupResponse.stateName;
        pickupCountry = pickupQuoteData?.country || pickupResponse.countryCode;
        deliveryCity = deliveryQuoteData?.city || deliveryResponse.cityName;
        deliveryState = deliveryQuoteData?.state || deliveryResponse.stateName;
        deliveryCountry =
          deliveryQuoteData?.country || deliveryResponse.countryCode;
      } else if (quoteData.isMultipleQuote) {
        const pickupInfos = quoteData.pickupInfo.map((pickup) => {
          const pickupCopy = JSON.parse(JSON.stringify(pickup));
          const pickupQuote = pickup?.pickup_from?.split('|')?.[1]?.split(',');
          const pickAddress = pickup?.pickup_from?.split('|')?.[0];

          pickupCopy.pickup_address = {
            company:
              pickup?.pickup_address?.company ||
              pickupCopy?.pickupLocation?.company,
            contactperson:
              pickup?.pickup_address?.contactperson ||
              pickupCopy?.pickupLocation?.contactperson,
            contactnumber:
              pickup?.pickup_address?.contactnumber ||
              pickupCopy?.pickupLocation?.contactnumber,
            address1: pickup?.pickup_address?.address1 || pickAddress,
            address2: pickup?.pickup_address?.address2,
            city: pickup?.pickup_address?.city || pickupQuote?.[1]?.trim(),
            state: pickup?.pickup_address?.state || pickupQuote?.[2]?.trim(),
            country:
              pickup?.pickup_address?.country || pickupQuote?.[3]?.trim(),
            reference: pickup?.pickup_address?.reference,
            zip: pickup?.pickup_zip_code
          };
          pickupCopy.pickup_date =
            pickupCopy.pickup_date !== 'flexible date'
              ? new Date(pickupCopy.pickup_date)
              : '';
          pickupCopy.pickup_time =
            pickupCopy.pickup_time !== 'flexible time'
              ? new Date(moment(pickupCopy.pickup_time, 'hh:mm A').format())
              : '';
          pickupCopy.pickup_close_time = pickupCopy.pickup_close_time
            ? new Date(moment(pickupCopy.pickup_close_time, 'hh:mm A').format())
            : '';
          return pickupCopy;
        });
        const deliveryInfos = quoteData.deliveryInfo.map((del) => {
          const deliveryCopy = JSON.parse(JSON.stringify(del));
          const deliveryQuote = del?.delivery_to?.split('|')?.[1]?.split(',');
          const delAddress = del?.delivery_to?.split('|')?.[0];

          deliveryCopy.delivery_address = {
            company:
              del?.delivery_address?.company ||
              deliveryCopy?.deliveryLocation?.company,
            contactperson:
              del?.delivery_address?.contactperson ||
              deliveryCopy?.deliveryLocation?.contactperson,
            contactnumber:
              del?.delivery_address?.contactnumber ||
              deliveryCopy?.deliveryLocation?.contactnumber,
            address1: del?.delivery_address?.address1 || delAddress,
            address2: del?.delivery_address?.address2,
            city: del?.delivery_address?.city || deliveryQuote?.[1]?.trim(),
            state: del?.delivery_address?.state || deliveryQuote?.[2]?.trim(),
            country:
              del?.delivery_address?.country || deliveryQuote?.[3]?.trim(),
            reference: del?.delivery_address?.reference,
            zip: del?.delivery_zip_code
          };
          deliveryCopy.delivery_date =
            deliveryCopy.delivery_date !== 'flexible date'
              ? new Date(deliveryCopy.delivery_date)
              : '';
          deliveryCopy.delivery_time =
            deliveryCopy.delivery_time !== 'flexible time'
              ? new Date(moment(deliveryCopy.delivery_time, 'hh:mm A').format())
              : '';
          deliveryCopy.delivery_close_time = del.delivery_close_time
            ? new Date(
                moment(deliveryCopy.delivery_close_time, 'hh:mm A').format()
              )
            : '';
          return deliveryCopy;
        });
        reset({
          pickupInfo: pickupInfos,
          deliveryInfo: deliveryInfos
        });
      } else {
        pickupAddress1 = isPickupAddressChanged
          ? quoteData?.pickup_from?.split('|')?.[0]
          : pickupQuoteData?.address1 || pickupDataAddress?.[0].trim();
        pickupAddress2 = isPickupAddressChanged
          ? ''
          : pickupQuoteData?.address2;
        pickupCity = pickupData?.[1]?.trim();
        pickupState = pickupData?.[2]?.trim();
        pickupCountry = pickupQuoteData?.country || pickupData?.[3]?.trim();
        deliveryAddress1 = isDeliveryAddressChanged
          ? quoteData?.delivery_to?.split('|')?.[0]
          : deliveryQuoteData?.address1 || deliveryDataAddress?.[0].trim();
        deliveryAddress2 = isDeliveryAddressChanged
          ? ''
          : deliveryQuoteData?.address2;
        deliveryCity = deliveryData?.[1]?.trim();
        deliveryState = deliveryData?.[2]?.trim();
        deliveryCountry =
          deliveryQuoteData?.country || deliveryData?.[3]?.trim();
      }

      if (!quoteData.isMultipleQuote) {
        reset({
          pickupInfo: [
            {
              pickup_address: {
                company: isPickupAddressChanged
                  ? ''
                  : quoteData?.pickup_address?.company ||
                    quoteData?.pickupLocation?.company,
                contactperson: isPickupAddressChanged
                  ? ''
                  : quoteData?.pickup_address?.contactperson ||
                    quoteData?.pickupLocation?.contactperson,
                contactnumber: isPickupAddressChanged
                  ? ''
                  : quoteData?.pickup_address?.contactnumber ||
                    quoteData?.pickupLocation?.contactnumber,
                address1: pickupAddress1,
                address2: pickupAddress2,
                city: pickupCity,
                state: pickupState,
                country: pickupCountry,
                zip: quoteData?.pickup_zip_code,
                reference: quoteData?.pickup_address?.reference
              },
              pickup_special_instructions:
                quoteData?.pickup_special_instructions,
              pickup_date:
                response?.data?.quote.pickup_date !== 'flexible date'
                  ? new Date(response?.data?.quote.pickup_date)
                  : '',
              pickup_time:
                response?.data?.quote.pickup_time !== 'flexible time'
                  ? new Date(
                      moment(
                        response.data.quote.pickup_time,
                        'hh:mm A'
                      ).format()
                    )
                  : '',
              pickup_close_time: response?.data?.quote.pickup_close_time
                ? new Date(
                    moment(
                      response.data.quote.pickup_close_time,
                      'hh:mm A'
                    ).format()
                  )
                : ''
            }
          ],
          deliveryInfo: [
            {
              delivery_address: {
                company: isDeliveryAddressChanged
                  ? ''
                  : quoteData?.delivery_address?.company ||
                    quoteData?.deliveryLocation?.company,
                contactperson: isDeliveryAddressChanged
                  ? ''
                  : quoteData?.delivery_address?.contactperson ||
                    quoteData?.deliveryLocation?.contactperson,
                contactnumber: isDeliveryAddressChanged
                  ? ''
                  : quoteData?.delivery_address?.contactnumber ||
                    quoteData?.deliveryLocation?.contactnumber,
                address1: deliveryAddress1,
                address2: deliveryAddress2,
                city: deliveryCity,
                state: deliveryState,
                country: deliveryCountry,
                zip: quoteData?.delivery_zip_code,
                reference: quoteData?.delivery_address?.reference
              },
              delivery_special_instructions:
                quoteData?.delivery_special_instructions,

              delivery_date:
                response?.data?.quote.delivery_date !== 'flexible date'
                  ? new Date(response.data.quote.delivery_date)
                  : '',

              delivery_time:
                response?.data?.quote.delivery_time !== 'flexible time'
                  ? new Date(
                      moment(
                        response.data.quote.delivery_time,
                        'h:mm a'
                      ).format()
                    )
                  : '',
              delivery_close_time: response?.data?.quote.delivery_close_time
                ? new Date(
                    moment(
                      response.data.quote.delivery_close_time,
                      'h:mm a'
                    ).format()
                  )
                : ''
            }
          ]
        });
        if (
          !getValues('delivery_date') &&
          quoteData?.service_type === LTL_FREIGHT
        ) {
          const carrierTransitDate =
            quoteData?.[quoteData?.carrier_type]?.transitDate;

          setValue(
            'deliveryInfo[0].delivery_date',
            new Date(carrierTransitDate)
          );
        }
      }

      setisAPICalled(true);
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  }
  const pickupDate = watch('pickup_date');
  const pickupState = quote?.pickup_from
    ?.split('|')
    ?.[Number(quote?.pickup_from?.split('|').length) - 1].trim();
  const deliveryState = quote?.delivery_to
    ?.split('|')
    ?.[Number(quote?.delivery_to?.split('|').length) - 1].trim();

  const onSubmitForm = async (data) => {
    let newData = data;
    if (!quote.isMultipleQuote) {
      const pickupAddressLine1 = quote?.pickup_from?.split('|')?.[0];
      const deliveryAddressLine1 = quote?.delivery_to?.split('|')?.[0];
      newData = {
        pickup_address: JSON.stringify(data.pickupInfo[0].pickup_address),
        delivery_address: JSON.stringify(data.deliveryInfo[0].delivery_address),
        pickup_special_instructions:
          data.pickupInfo[0].pickup_special_instructions,
        delivery_special_instructions:
          data.deliveryInfo[0].delivery_special_instructions,
        pickup_date: formatPickupDeliveryDate(
          data.pickupInfo[0].pickup_date,
          false
        ),
        pickup_time: formatPickupDeliveryTime(
          data.pickupInfo[0].pickup_time,
          false
        ),
        pickup_close_time: !data.pickupInfo[0].pickup_close_time
          ? ''
          : formatPickupDeliveryTime(
              data.pickupInfo[0].pickup_close_time,
              false
            ),
        delivery_date: formatPickupDeliveryDate(
          data.deliveryInfo[0].delivery_date,
          false
        ),
        delivery_time: formatPickupDeliveryTime(
          data.deliveryInfo[0].delivery_time,
          false
        ),
        delivery_close_time: !data.deliveryInfo[0].delivery_close_time
          ? ''
          : formatPickupDeliveryTime(
              data.deliveryInfo[0].delivery_close_time,
              false
            ),

        is_pickup_time_flexible: false,
        is_pickup_flexible: false,
        is_delivery_time_flexible: false,
        is_delivery_flexible: false,
        ...(!pickupAddressLine1.trim() && {
          pickup_from: `${data.pickupInfo[0].pickup_address.address1}${data.pickupInfo[0].pickup_address.address2.trim() ? ` | ${data.pickupInfo[0].pickup_address.address2}` : ''}${quote?.pickup_from ?? ''}`,
          pickup_formatted_address: `${data.pickupInfo[0].pickup_address.address1}${data.pickupInfo[0].pickup_address.address2.trim() ? `, ${data.pickupInfo[0].pickup_address.address2}` : ''}${quote?.pickup_formatted_address ? `, ${quote?.pickup_formatted_address}` : ''}`
        }),
        ...(!deliveryAddressLine1.trim() && {
          delivery_to: `${data.deliveryInfo[0].delivery_address.address1}${data.deliveryInfo[0].delivery_address.address2.trim() ? ` | ${data.deliveryInfo[0].delivery_address.address2}` : ''}${quote?.delivery_to ?? ''}`,
          delivery_formatted_address: `${data.deliveryInfo[0].delivery_address.address1}${data.deliveryInfo[0].delivery_address.address2.trim() ? `, ${data.deliveryInfo[0].delivery_address.address2}` : ''}${quote?.delivery_formatted_address ? `, ${quote?.delivery_formatted_address}` : ''}`
        }),
        ...(pickupAddressLine1.trim() &&
          pickupAddressLine1 !==
            data?.pickupInfo[0]?.pickup_address?.address1.trim() && {
            pickup_from: `${data.pickupInfo[0].pickup_address.address1}${data.pickupInfo[0].pickup_address.address2.trim() ? ` | ${data.pickupInfo[0].pickup_address.address2}` : ''}${pickupState}`,
            pickup_formatted_address: `${data.pickupInfo[0].pickup_address.address1}${data.pickupInfo[0].pickup_address.address2.trim() ? `, ${data.pickupInfo[0].pickup_address.address2}` : ''}, ${pickupState}`
          }),
        ...(deliveryAddressLine1.trim() &&
          deliveryAddressLine1 !==
            data?.deliveryInfo[0]?.delivery_address?.address1.trim() && {
            delivery_to: `${data.deliveryInfo[0].delivery_address.address1}${data.deliveryInfo[0].delivery_address.address2.trim() ? ` | ${data.deliveryInfo[0].delivery_address.address2}` : ''}${quote?.delivery_to ?? ''}`,
            delivery_formatted_address: `${data.deliveryInfo[0].delivery_address.address1}${data.deliveryInfo[0].delivery_address.address2.trim() ? `, ${data.deliveryInfo[0].delivery_address.address2}` : ''}, ${deliveryState}`
          })
      };
    } else {
      const newPickupData = data.pickupInfo.map((pick, index) => {
        const pickCopy = JSON.parse(JSON.stringify(pick));
        const pickAddress1 = quote?.pickupInfo[index]?.pickup_from
          ?.split('|')?.[0]
          ?.trim();
        const pickState = quote?.pickupInfo[index]?.pickup_from
          ?.split('|')
          ?.[
            Number(quote?.pickupInfo[index]?.pickup_from?.split('|').length) - 1
          ]?.trim();

        pickCopy.pickup_date = formatPickupDeliveryDate(
          pick.pickup_date,
          false
        );
        pickCopy.pickup_time = formatPickupDeliveryTime(
          pick.pickup_time,
          false
        );
        pickCopy.pickup_close_time = !pick.pickup_close_time
          ? ''
          : formatPickupDeliveryTime(pick.pickup_close_time, false);
        pickCopy.is_pickup_time_flexible = false;
        pickCopy.is_pickup_flexible = false;

        if (
          !pickAddress1 ||
          (pick.pickup_address.address2 &&
            pickAddress1 === pick?.pickup_address?.address1.trim())
        ) {
          pickCopy.pickup_from = `${pick.pickup_address.address1}${pick.pickup_address.address2.length !== 0 ? ` | ${pick.pickup_address.address2}` : ''}${quote?.pickupInfo[index].pickup_from ?? ''}`;
          pickCopy.pickup_formatted_address = `${pick.pickup_address.address1}${pick.pickup_address.address2.length !== 0 ? `, ${pick.pickup_address.address2}` : ''}${pickState ? `, ${pickState}` : ''}`;
        }

        if (
          pickAddress1 &&
          pickAddress1 !== pick?.pickup_address?.address1.trim()
        ) {
          pickCopy.pickup_from = `${pick.pickup_address.address1}${pick.pickup_address.address2.length !== 0 ? ` | ${pick.pickup_address.address2}` : ''} | ${pickState}`;
          pickCopy.pickup_formatted_address = `${pick.pickup_address.address1}${pick.pickup_address.address2.length !== 0 ? `, ${pick.pickup_address.address2}` : ''}${pickState ? `, ${pickState}` : ''}`;
        }

        return pickCopy;
      });
      const newDeliveryData = data.deliveryInfo.map((del, index) => {
        const delCopy = JSON.parse(JSON.stringify(del));
        const delAddress1 = quote?.deliveryInfo[index]?.delivery_to
          ?.split('|')?.[0]
          ?.trim();
        const delState = quote?.deliveryInfo[index]?.delivery_to
          ?.split('|')
          ?.[
            Number(quote?.deliveryInfo[index]?.delivery_to?.split('|').length) -
              1
          ]?.trim();
        delCopy.delivery_date = formatPickupDeliveryDate(
          del.delivery_date,
          false
        );
        delCopy.delivery_time = formatPickupDeliveryTime(
          del.delivery_time,
          false
        );
        delCopy.delivery_close_time = !del.delivery_close_time
          ? ''
          : formatPickupDeliveryTime(del.delivery_close_time, false);

        delCopy.is_delivery_flexible = false;
        delCopy.is_delivery_time_flexible = false;

        if (
          !delAddress1 ||
          (del.delivery_address.address2 &&
            delAddress1 === del?.delivery_address?.address1.trim())
        ) {
          delCopy.delivery_to = `${del.delivery_address.address1}${del.delivery_address.address2.length !== 0 ? ` | ${del.delivery_address.address2}` : ''}${quote?.deliveryInfo[index]?.delivery_to ?? ''}`;
          delCopy.delivery_formatted_address = `${del.delivery_address.address1}${del.delivery_address.address2.length !== 0 ? `, ${del.delivery_address.address2}` : ''}, ${delState ?? ''}`;
        }

        if (
          delAddress1 &&
          delAddress1 !== del?.delivery_address?.address1.trim()
        ) {
          delCopy.delivery_to = `${del.delivery_address.address1}${del.delivery_address.address2.length !== 0 ? ` | ${del.delivery_address.address2}` : ''} | ${delState}`;
          delCopy.delivery_formatted_address = `${del.delivery_address.address1}${del.delivery_address.address2.length !== 0 ? `, ${del.delivery_address.address2}` : ''}${delState ? `, ${delState}` : ''}`;
        }

        return delCopy;
      });
      newData = {
        ...data,
        pickupInfo: JSON.stringify(newPickupData),
        deliveryInfo: JSON.stringify(newDeliveryData)
      };
    }
    try {
      if (
        isValid &&
        (quote.service_type === LTL_FREIGHT ||
          quote.service_type === BLANKET_WRAP)
      ) {
        // history.push(`/book-now-quote/payment/${quoteId}`, { data, quote });
        const response = await bookNowQuoteService({
          ...newData,
          id: quoteId,
          cargos: JSON.stringify(data.cargos),
          assignDateTime:
            quote.service_type === LTL_FREIGHT
              ? moment().format('MM/DD/YYYY HH:mm')
              : null,
          bookDateTime: moment().format('MM/DD/YYYY HH:mm')
        });
        if (response.data.flag) {
          // toast.success('Payment successfully completed!');
          const quoteData = response?.data?.quote;

          const queryObj = { tracking: quoteData?.internalTrackingNumber };
          if (quote.service_type === LTL_FREIGHT) {
            queryObj.pro = quoteData?.[quoteData.carrier_type]?.pickupNumber;
            queryObj.quote =
              quoteData?.[quoteData.carrier_type]?.[
                `quoteNumber${quoteData.carrier_type.toUpperCase()}`
              ];
            if (quoteData?.[quoteData.carrier_type]?.confirmatonNumber)
              queryObj.confirmation =
                quoteData?.[quoteData.carrier_type]?.confirmatonNumber;
          }
          const queryString = new URLSearchParams(queryObj).toString();
          toast.success(response.data.message);
          navigate(`/thank-you?${queryString}`, { replace: true });
        } else {
          toast.error(response.data.message);
        }
      } else if (isValid) {
        if (
          quote.bidType === QUOTE_FROM_NET ||
          quote.bidType === FIXED_AMOUNT
        ) {
          const response = await bookNowQuoteService({
            ...newData,
            id: quoteId,
            bookDateTime: moment().format('MM/DD/YYYY HH:mm')
          });
          if (response.data.flag) {
            const quoteData = response?.data?.quote;

            const queryObj = { tracking: quoteData?.internalTrackingNumber };

            const queryString = new URLSearchParams(queryObj).toString();
            toast.success(response.data.message);
            navigate(`/shipper-thank-you?${queryString}`, { replace: true });
          }
        } else {
          quote.pickup_address = newData.pickup_address;
          quote.delivery_address = newData.delivery_address;
          quote.pickup_special_instructions =
            newData.pickup_special_instructions;
          quote.delivery_special_instructions =
            newData.delivery_special_instructions;
          quote.pickup_date = newData.pickup_date;

          quote.pickup_time = newData.pickup_time;

          quote.pickup_close_time = newData.pickup_close_time;

          quote.is_pickup_flexible = false;
          quote.is_pickup_time_flexible = false;

          quote.delivery_date = newData.delivery_date;

          quote.delivery_time = newData.delivery_time;

          quote.delivery_close_time = newData.delivery_close_time;

          quote.is_delivery_flexible = false;
          quote.is_delivery_time_flexible = false;

          navigate(`/shipper-add-signature/${bidId}`, {
            state: quote.isMultipleQuote ? { ...quote, ...newData } : quote
          });
        }
      }
    } catch (error) {
      console.log(error.message);
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchQuoteDetails();
  }, [quoteId]);

  useEffect(() => {
    if (quote && Object.keys(quote).length) {
      const bookingStatus = getBookingStatus(quote, quote?.carrier_type);
      setIsBookingAllowed(bookingStatus);
    }
  }, [quote]);

  return (
    <section>
      <div className="py-20 bg-gray-50 radius-for-skewed">
        <div className="container mx-auto px-4">
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="mb-6 px-3 p-4 relative">
              <h3 className="text-3xl font-bold text-navy-500">
                Pickup Address
              </h3>
            </div>
            {isAPICalled
              ? bookData?.pickupInfo?.map((pickup, index) => {
                  return (
                    <div
                      className="w-full md:w-1/2 px-3 mb-6 pb-6 md:pr-12 border-b-2 border-gray-200"
                      key={pickup?.id}
                    >
                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Company Name
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.company`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address
                          ?.company && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address
                                .company.message
                            }
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Address line 1
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.address1`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address
                          ?.address1 && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address
                                .address1.message
                            }
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Address line 2
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.address2`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address
                          ?.address2 && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address
                                .address2.message
                            }
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">City</label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.city`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address?.city && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address.city
                                .message
                            }
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label
                          className="block tracking-wide mb-2"
                          htmlFor="state"
                        >
                          State
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.state`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                          readOnly
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address?.state && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address.state
                                .message
                            }
                          </span>
                        )}
                      </div>
                      <div className="w-full md:w-9/12">
                        <label
                          className="block tracking-wide mb-2"
                          htmlFor="state"
                        >
                          Country
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.country`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                          readOnly
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address
                          ?.country && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address
                                .country.message
                            }
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Zip code
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.zip`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                          readOnly
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address?.zip && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address.zip
                                .message
                            }
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Pickup Date
                        </label>
                        <Controller
                          render={({
                            field: { ref, name, value, onChange }
                          }) => (
                            <DatePickerInput
                              name={name}
                              ref={ref}
                              handleDateChange={(e) => onChange(e)}
                              selectedDate={value}
                              isDisabled={false}
                            />
                          )}
                          name={`pickupInfo[${index}].pickup_date`}
                          control={control}
                        />
                        {errors?.pickupInfo?.[index]?.pickup_date && (
                          <span className="text-md text-red-700">
                            {errors?.pickupInfo?.[index]?.pickup_date.message}
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Pickup Time
                        </label>
                        <Controller
                          render={({
                            field: { ref, name, value, onChange }
                          }) => (
                            <TimePickerInput
                              name={name}
                              ref={ref}
                              handleDateChange={(e) => onChange(e)}
                              selectedDate={value}
                              isDisabled={false}
                            />
                          )}
                          name={`pickupInfo[${index}].pickup_time`}
                          control={control}
                        />
                        {errors?.pickupInfo?.[index]?.pickup_time && (
                          <span className="text-md text-red-700">
                            {errors?.pickupInfo?.[index]?.pickup_time?.message}
                          </span>
                        )}
                      </div>
                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Pickup Close Time
                        </label>
                        <Controller
                          render={({
                            field: { ref, name, value, onChange }
                          }) => (
                            <TimePickerInput
                              name={name}
                              ref={ref}
                              handleDateChange={(e) => onChange(e)}
                              selectedDate={value}
                              isDisabled={false}
                            />
                          )}
                          name={`pickupInfo[${index}].pickup_close_time`}
                          control={control}
                        />
                        {errors?.pickupInfo?.[index]?.pickup_close_time && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_close_time
                                ?.message
                            }
                          </span>
                        )}
                      </div>
                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Contact person name
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.contactperson`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address
                          ?.contactperson && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address
                                .contactperson.message
                            }
                          </span>
                        )}
                      </div>

                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Phone number
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.contactnumber`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address
                          ?.contactnumber && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address
                                .contactnumber.message
                            }
                          </span>
                        )}
                      </div>
                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          Special pickup instructions
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_special_instructions`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]
                          ?.pickup_special_instructions && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]
                                ?.pickup_special_instructions.message
                            }
                          </span>
                        )}
                      </div>
                      <div className="w-full md:w-9/12">
                        <label className="block tracking-wide mb-2">
                          SHIPPER REFERENCE #
                        </label>
                        <input
                          {...register(
                            `pickupInfo[${index}].pickup_address.reference`
                          )}
                          className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                          type="text"
                        />
                        {errors?.pickupInfo?.[index]?.pickup_address
                          ?.reference && (
                          <span className="text-md text-red-700">
                            {
                              errors?.pickupInfo?.[index]?.pickup_address
                                .reference.message
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  );
                })
              : ''}
            <div className="mb-6 px-3 p-4 relative">
              <h3 className="text-3xl font-bold text-navy-500">
                Delivery Address
              </h3>
            </div>
            {isAPICalled &&
              bookData?.deliveryInfo?.map((_, index) => {
                return (
                  <div className="w-full md:w-1/2 px-3 mb-6 pb-6 md:pr-12 border-b-2 border-gray-200">
                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Company Name
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.company`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.company && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .company.message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Address line 1
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.address1`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.address1 && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .address1.message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Address line 2
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.address2`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.address2 && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .address2.message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">City</label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.city`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.city && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address.city
                              .message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">State</label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.state`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                        readOnly
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.state && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .state.message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Country
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.country`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                        readOnly
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.state && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .state.message
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Zip code
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.zip`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                        readOnly
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address?.zip && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address.zip
                              .message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Delivery Date
                      </label>
                      <Controller
                        render={({ field: { ref, name, value, onChange } }) => (
                          <DatePickerInput
                            name={name}
                            ref={ref}
                            handleDateChange={(e) => onChange(e)}
                            selectedDate={value}
                            minDate={moment(pickupDate).toDate()}
                            isDisabled={false}
                          />
                        )}
                        name={`deliveryInfo[${index}].delivery_date`}
                        control={control}
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_date && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_date
                              ?.message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Delivery Time
                      </label>
                      <Controller
                        render={({ field: { ref, name, value, onChange } }) => (
                          <TimePickerInput
                            name={name}
                            ref={ref}
                            handleDateChange={(e) => onChange(e)}
                            selectedDate={value}
                            isDisabled={false}
                          />
                        )}
                        name={`deliveryInfo[${index}].delivery_time`}
                        control={control}
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_time && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_time
                              ?.message
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Delivery Close Time
                      </label>
                      <Controller
                        render={({ field: { ref, name, value, onChange } }) => (
                          <TimePickerInput
                            name={name}
                            ref={ref}
                            handleDateChange={(e) => onChange(e)}
                            selectedDate={value}
                            isDisabled={false}
                          />
                        )}
                        name={`deliveryInfo[${index}].delivery_close_time`}
                        control={control}
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_close_time && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_close_time
                              ?.message
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Contact person name
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.contactperson`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.contactperson && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .contactperson.message
                          }
                        </span>
                      )}
                    </div>

                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Phone number
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.contactnumber`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.contactnumber && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .contactnumber.message
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        Special delivery instructions
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_special_instructions`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]
                        ?.delivery_special_instructions && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]
                              ?.delivery_special_instructions.message
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full md:w-9/12">
                      <label className="block tracking-wide mb-2">
                        CONSIGNEE REFERENCE #
                      </label>
                      <input
                        {...register(
                          `deliveryInfo[${index}].delivery_address.reference`
                        )}
                        className="appearance-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                        type="text"
                      />
                      {errors?.deliveryInfo?.[index]?.delivery_address
                        ?.reference && (
                        <span className="text-md text-red-700">
                          {
                            errors?.deliveryInfo?.[index]?.delivery_address
                              .reference.message
                          }
                        </span>
                      )}
                    </div>
                  </div>
                );
              })}
            {isAPICalled && quote.service_type === LTL_FREIGHT ? (
              <>
                <div className="mb-4 px-3 p-4 relative">
                  <h3 className="text-3xl font-bold text-navy-500">
                    NMFC Code
                  </h3>
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 pb-6 md:pr-12 border-b-2 border-gray-200">
                  {quote.cargos.map((cargo, index) => {
                    return (
                      <div
                        className="mb-6 mt-6 lg:mb-0 w-full lg:w-full px-3"
                        key={cargo?.id}
                      >
                        <h3>{cargo.desc}</h3>
                        <div className="p-3 flex flex-wrap bg-gray-100 border border-gray-100 shadow">
                          <div className="w-2/3 flex-1">
                            <input
                              type="input"
                              placeholder="Enter NMFC code"
                              {...register(`cargos[${index}].id`)}
                              className="hidden"
                              defaultValue={cargo.id}
                            />
                            <input
                              type="input"
                              placeholder="Enter NMFC code"
                              {...register(`cargos[${index}].nmfc_code`)}
                              className="appearance-none border-none block w-full border border-gray-400 py-3 px-4 mb-3 leading-tight focus:outline-none"
                              defaultValue={cargo.nmfc_code}
                            />
                            <p>{`${cargo.length} in x ${cargo.width} in x ${cargo.height} in x ${cargo.weight} lbs`}</p>
                            {errors?.cargos?.length > 0 && (
                              <span className="text-md text-red-700">
                                {errors?.cargos[index]?.nmfc_code.message}
                              </span>
                            )}
                          </div>
                          {cargo?.files?.fd &&
                          cargo?.files?.filename !== 'cargo_default.png' ? (
                            <div className="ml-8">
                              <span className="mb-4 lg:mb-0 mr-6 inline-block p-1 md:p-1">
                                <img
                                  className="h-20 w-20 md:w-20 md:h-20"
                                  src={`${process.env.REACT_APP_API_URL}/${cargo?.files?.fd}`}
                                  alt="cargo img"
                                />
                              </span>
                            </div>
                          ) : (
                            <div className="ml-8">
                              <span className="mb-4 lg:mb-0 inline-block p-3 md:p-5  bg-navy-50 border border-navy-50">
                                <svg
                                  className="h-8 w-8 md:w-12 md:h-12"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="27"
                                  height="27"
                                  viewBox="0 0 27 27"
                                >
                                  <path
                                    id="Icon_material-image"
                                    data-name="Icon material-image"
                                    d="M31.5,28.5V7.5a3.009,3.009,0,0,0-3-3H7.5a3.009,3.009,0,0,0-3,3v21a3.009,3.009,0,0,0,3,3h21A3.009,3.009,0,0,0,31.5,28.5ZM12.75,20.25l3.75,4.515L21.75,18l6.75,9H7.5Z"
                                    transform="translate(-4.5 -4.5)"
                                    fill="#1d4690"
                                  />
                                </svg>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : null}
            <div className="w-full md:w-1/2 px-3 mb-6 pb-6 md:pr-12">
              <div className="text-left w-full md:w-9/12 mt-6">
                <button
                  type="submit"
                  disabled={!isBookingAllowed}
                  className={`mr-12 inline-block pl-4 mb-2 w-full py-4 transition duration-75 text-white text-xl font-bold  text-left relative ${isBookingAllowed ? 'bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700' : 'cursor-not-allowed bg-gray-500 border-gray-500'}`}
                >
                  {[QUOTE_FROM_NET, FIXED_AMOUNT].includes(quote?.bidType)
                    ? 'Book my load'
                    : 'Accept & Sign'}
                  <span className="pointer-events-none absolute inset-y-0 right-0 px-2 mr-2 mt-2 text-3xl">
                    &#10003;
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default BookNowQuotePage;
