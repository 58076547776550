import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import useGenerateInvoice from '../../customHooks/useGenerateInvoice';

const GenerateInvoiceButton = ({
  quoteId,
  fetchQuoteDetails,
  fetchOpenInvoices
}) => {
  const handleGenerateInvoice = useGenerateInvoice();
  const handleOnClick = async (e) => {
    try {
      e.preventDefault();
      await handleGenerateInvoice([quoteId]);
      if (fetchQuoteDetails) {
        fetchQuoteDetails(quoteId);
      }
      if (fetchOpenInvoices) {
        fetchOpenInvoices(true, 1);
      }
    } catch (error) {
      console.log({ error });
      toast.error(error.message ?? 'Something went wrong!');
    }
  };
  return (
    <button
      type="button"
      onClick={handleOnClick}
      className="flex flex-row justify-between items-center py-3 px-4 w-full
      bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
      hover:border-navy-600 active:border-navy-700
        text-sm font-bold transition duration-200 text-left"
    >
      Generate Invoice
      {fetchQuoteDetails && <RightArrow className="w-5 h-5 font-bold" />}
    </button>
  );
};

export default GenerateInvoiceButton;

GenerateInvoiceButton.propTypes = {
  quoteId: PropTypes.string.isRequired,
  fetchQuoteDetails: PropTypes.func,
  fetchOpenInvoices: PropTypes.func
};

GenerateInvoiceButton.defaultProps = {
  fetchQuoteDetails: null,
  fetchOpenInvoices: null
};
