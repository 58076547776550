import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import ChevronRightIcon from '../ChevronRightIcon';
import QuoteIcon from '../QuoteIcon';
import {
  BLANKET_WRAP,
  LTL_FREIGHT
} from '../../constants/service_type.constants';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';
import { getQuotePrice, getServiceTypeName } from '../../utils/quote.util';
import {
  INVOICE_STATUS,
  useIsInvoiceAuthorizedUser
} from '../../constants/invoice.constants';
import DownloadInvoiceButton from '../DownloadInvoiceButton/DownloadInvoiceButton';
import SendInvoiceButton from '../SendInvoiceButton';
import MarkAsPaidButton from '../MarkAsPaidButton';
import GenerateInvoiceButton from '../GenerateInvoiceButton';

const SalesRepCard = memo(
  ({
    summaryPath,
    quote,
    showEnroute,
    loadType,
    fetchOpenInvoices,
    selectedQuotes,
    setSelectedQuotes
  }) => {
    const isUserAuthorized = useIsInvoiceAuthorizedUser();
    const [isChecked, setIsChecked] = useState(false);

    const { OPEN_BID } = BID_TYPE;
    const { PENDING_QUOTE } = BID_STATUS;
    const { UNPAID, SENT, PAID } = INVOICE_STATUS;

    const handleCheckboxClick = (e) => {
      e.stopPropagation();
      if (!isChecked) {
        setIsChecked(true);
        setSelectedQuotes([
          ...selectedQuotes,
          { quoteId: quote?.id, amount: getQuotePrice(quote) }
        ]);
      } else {
        setIsChecked(false);
        const selectedQotesCopy = [...selectedQuotes];
        const updatedSelected = selectedQotesCopy.filter(
          (item) => item.quoteId !== quote?.id
        );
        setSelectedQuotes(updatedSelected);
      }
    };

    useEffect(() => {
      if (
        loadType === 'salesRep-open-invoices' &&
        quote?.invoiceStatus === SENT
      ) {
        setIsChecked(false);
      }
    }, [quote]);

    useEffect(() => {
      if (!selectedQuotes.length) {
        setIsChecked(false);
      }
    }, [selectedQuotes]);

    return (
      <div className="w-full">
        <Link to={summaryPath}>
          <div className="p-6 flex flex-wrap bg-gray-100 border border-gray-100 shadow items-center">
            {loadType === 'salesRep-open-invoices' &&
              quote?.invoiceStatus !== PAID && (
                <div className="h-5 w-5 mr-5">
                  <input
                    type="checkbox"
                    className="invoice-checkbox"
                    checked={isChecked}
                    onClick={handleCheckboxClick}
                  />
                </div>
              )}
            <QuoteIcon />
            <div className="flex justify-between w-full lg:w-2/3 items-center">
              <div>
                <h3 className="mb-2 text-xl font-bold font-heading">
                  {quote.company}
                </h3>
                <p className="text-black ">
                  From:{' '}
                  <span className="font-bold">
                    {quote?.isMultipleQuote
                      ? quote?.pickupInfo?.length &&
                        quote?.pickupInfo
                          ?.map((pick) => pick.pickup_zip_code)
                          ?.join(', ')
                      : quote.pickup_zip_code}
                  </span>{' '}
                  &nbsp; To:{' '}
                  <span className="font-bold">
                    {quote?.isMultipleQuote
                      ? quote?.deliveryInfo?.length &&
                        quote?.deliveryInfo
                          ?.map((del) => del.delivery_zip_code)
                          ?.join(', ')
                      : quote.delivery_zip_code}
                  </span>
                </p>
                <p className="text-black ">
                  Service Type:{' '}
                  <span className="font-bold">
                    {`${getServiceTypeName(quote.service_type)} ${
                      quote.booked && quote.carrier_type
                        ? `( ${quote.carrier_type} )`
                        : ''
                    }`}
                  </span>
                </p>
                {quote?.tracking_details?.trackingNumber ? (
                  <p className="text-black ">
                    PRO:{' '}
                    <span className="font-bold">
                      {quote?.tracking_details?.trackingNumber}
                    </span>
                  </p>
                ) : null}
                {quote.bidType === OPEN_BID ? (
                  <h3 className="mb-2 text-xl font-bold font-heading text-green-700">
                    Open Bid
                  </h3>
                ) : (
                  <h3 className="mb-2 text-xl font-bold font-heading">
                    Budget: ${getQuotePrice(quote)}
                  </h3>
                )}

                {showEnroute &&
                  ![LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
                  (quote.isPickupByCarrier ? (
                    <h3 className="text-green-700">
                      Your shipment has been picked up by Carrier at{' '}
                      {quote.carrierPickupDate} {quote.carrierPickUpTime} .
                    </h3>
                  ) : (
                    <h3 className="text-red-600">
                      Your shipment is not picked up by carrier yet.
                    </h3>
                  ))}
                {isUserAuthorized &&
                  loadType === 'salesRep-completed-quotes' &&
                  quote?.isInvoiceGenerated && (
                    <span className="text-green-700">
                      Invoice has been generated on{' '}
                      {dayjs(quote.invoiceGeneratedDate).format('MM/DD/YYYY')}.
                    </span>
                  )}
              </div>
              <div className="flex flex-row items-center gap-2">
                {[
                  'salesRep-pending-quotes',
                  'salesRep-active-quotes',
                  'salesRep-enroute-quotes'
                ].includes(loadType) && (
                  <Link
                    to={`/salesRep/enroute-quote-details/tracking/${quote.id}`}
                    className="bg-navy-500 py-2 px-6 text-white z-50 m-2"
                  >
                    Track
                  </Link>
                )}
                {loadType === 'salesRep-open-invoices' &&
                  [UNPAID].includes(quote?.invoiceStatus) && (
                    <GenerateInvoiceButton
                      quoteId={quote?.id}
                      fetchOpenInvoices={fetchOpenInvoices}
                    />
                  )}
                {loadType === 'salesRep-open-invoices' &&
                  [SENT, PAID].includes(quote?.invoiceStatus) && (
                    <DownloadInvoiceButton
                      quoteId={quote?.id}
                      styling="bg-navy-500 py-2 px-4 text-white"
                    />
                  )}
                {loadType === 'salesRep-open-invoices' &&
                  [SENT].includes(quote?.invoiceStatus) && (
                    <div className="flex flex-row gap-2">
                      <SendInvoiceButton
                        quoteId={quote?.id}
                        title={
                          quote?.invoiceStatus === UNPAID ? 'Send' : 'Resend'
                        }
                        styling="bg-navy-500 py-1 px-3 text-white"
                        fetchOpenInvoices={fetchOpenInvoices}
                      />

                      <MarkAsPaidButton
                        quote={quote}
                        styling="bg-navy-500 py-2 px-4 text-white"
                      />
                    </div>
                  )}
                {![LTL_FREIGHT, BLANKET_WRAP].includes(quote.service_type) &&
                loadType === 'salesRep-pending-quotes' &&
                quote.bidStatus === PENDING_QUOTE &&
                quote.bidType === OPEN_BID ? (
                  <Link
                    to={`/salesRep/shipper-submission-list/${quote.id}`}
                    state={quote}
                    className="bg-navy-500 py-2 px-6 text-white z-50"
                  >
                    View Bids
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="ml-6">
              {quote?.internalTrackingNumber && (
                <span> #{quote.internalTrackingNumber}</span>
              )}
            </div>
            <div className="mt-auto ml-auto mb-auto">
              <ChevronRightIcon />
            </div>
          </div>
        </Link>
      </div>
    );
  }
);

export default SalesRepCard;

SalesRepCard.propTypes = {
  quote: PropTypes.object.isRequired,
  summaryPath: PropTypes.any.isRequired,
  showEnroute: PropTypes.bool.isRequired,
  loadType: PropTypes.string.isRequired,
  fetchOpenInvoices: PropTypes.func.isRequired,
  selectedQuotes: PropTypes.array.isRequired,
  setSelectedQuotes: PropTypes.func.isRequired
};
