import React, { useContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Select, ConfigProvider } from 'antd';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { getAllCompaniesBySalesRep } from '../../../services/salesrep.service';
import { getResponsibleUsersByCompany } from '../../../services/company.service';
import { isDuplicate, ROLE_TYPE } from '../../../constants/generalConstants';

const SalesRepCustomerSection = () => {
  const {
    validate,
    currentCustomer,
    setCurrentCustomer,
    cargos,
    setCargos,
    responsibleUser,
    setResponsibleUser,
    quote
  } = useContext(GlobalFormContext);

  const [allCustomers, setAllCustomers] = useState([]);
  const [responsibleUsers, setResponsibleUsers] = useState([]);
  const [isCustomerExisting, setIsCustomerExisting] = useState(false);

  const fetchAllUsers = async () => {
    try {
      const response = await getAllCompaniesBySalesRep();
      const activeCompanies = response?.data?.data.filter(
        (item) => item?.is_active === true
      );
      setAllCustomers(
        activeCompanies.map((customer) => ({
          value: customer.id,
          label: customer.name,
          ...customer
        }))
      );
    } catch (error) {
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  const handleSelect = (_, option) => {
    setCurrentCustomer(option);
    if (!isDuplicate() && cargos.length) {
      setCargos([]);
    }
  };
  const handleResponsibleUserSelect = (_, option) => {
    setResponsibleUser(option);
  };

  const fetchResponsibleUsers = async () => {
    try {
      const response = await getResponsibleUsersByCompany(currentCustomer?.id);
      const updatedUsers = response?.data?.data.map((item) => ({
        ...item,
        value: item?.id,
        label: `${item?.name} (${item?.role?.roleType})`
      }));

      let defaultValue;

      if (isDuplicate() && quote?.company?.id === currentCustomer?.id) {
        defaultValue = updatedUsers.find(
          (item) => item?.id === quote?.responsibleUser
        );
      } else {
        defaultValue = updatedUsers.find(
          (item) => item?.role?.id === ROLE_TYPE.MAIN
        );
      }
      setResponsibleUser(defaultValue ?? {});
      setResponsibleUsers(updatedUsers);
    } catch (error) {
      console.log({ error });
      toast.error(error?.response?.data.message ?? 'Something went wrong!');
    }
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (Object.keys(currentCustomer).length > 0) {
      setIsCustomerExisting(true);
      fetchResponsibleUsers();
    } else {
      setIsCustomerExisting(false);
      setResponsibleUser({});
      setResponsibleUsers([]);
    }
  }, [currentCustomer]);

  return (
    <div className="flex flex-col gap-2 w-full items-start">
      <span className="font-medium">Select Customer</span>
      <div className="flex flex-col w-full bg-navy-20 p-2 ">
        <div className="flex flex-row w-full gap-5 items-center">
          <ConfigProvider
            theme={{
              components: {
                Select: {
                  optionActiveBg: '#DEEBFF'
                }
              }
            }}
          >
            <Select
              value={
                Object.keys(currentCustomer).length
                  ? currentCustomer
                  : undefined
              }
              className={`text-left ${isCustomerExisting ? 'w-1/2' : 'w-full'}`}
              showSearch
              placeholder="Select customer"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={allCustomers}
              onSelect={handleSelect}
            />
          </ConfigProvider>
          {isCustomerExisting && (
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    optionActiveBg: '#DEEBFF'
                  }
                }
              }}
            >
              <Select
                value={
                  Object.keys(responsibleUser).length
                    ? responsibleUser
                    : undefined
                }
                className="text-left w-1/2"
                style={{
                  width: 515,
                  height: 32
                }}
                showSearch
                placeholder="Select responsible user"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={responsibleUsers}
                onSelect={handleResponsibleUserSelect}
              />
            </ConfigProvider>
          )}
          {isCustomerExisting && (
            <div className="flex flex-row w-1/2 gap-7">
              <div className="flex flex-col">
                <span className="text-gray-600 ">Credit Limit:</span>
                <span className="font-semibold">
                  {Object.keys(currentCustomer).length
                    ? Number(currentCustomer?.creditLimit).toFixed(2)
                    : 'NA'}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-gray-600 ">Open balance:</span>
                <span className="font-semibold">
                  {Object.keys(currentCustomer).length
                    ? Number(currentCustomer?.openBalance).toFixed(2)
                    : 'NA'}
                </span>
              </div>
              <div className="flex flex-col">
                <span className="text-gray-600">Terms:</span>
                <span className="font-semibold">
                  {Object.keys(currentCustomer).length
                    ? currentCustomer?.paymentTerms
                    : 'NA'}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {validate && !Object.keys(currentCustomer).length && (
        <span className="text-sm text-red-700 ml-3">
          Please select a Customer
        </span>
      )}
      {isCustomerExisting &&
        validate &&
        !Object.keys(responsibleUser).length && (
          <span className="text-sm text-red-700 ml-3">
            Please select a Responsible User
          </span>
        )}
    </div>
  );
};

export default SalesRepCustomerSection;
