/* eslint-disable jsx-a11y/no-onchange */
import React, { useEffect, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MdDelete } from 'react-icons/md';
import moment from 'moment';
import CargoDetailsCard from '../CargoDetailsCard/CargoDetailsCard';
import {
  DELIVERY_SERVICE_OPTION,
  PICKUP_SERVICE_OPTION,
  SERVICE_TYPES,
  GOODS_TYPE,
  PACKAGE_TYPE,
  GOODS_CONDITION,
  ADDITIONAL_FEES
} from '../../constants/quote.constants';
import { updateShipmentService } from '../../services/quote.service';
import { ReactComponent as RightArrow } from '../../assets/images/right_arrow.svg';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import TimePickerInput from '../TimePickerInput/TimePickerInput';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import {
  formatPickupDeliveryDate,
  formatPickupDeliveryTime
} from '../../utils/date_conversion.util';
import {
  TRUCKLOAD_FLAT,
  TRUCKLOAD_VAN
} from '../../constants/service_type.constants';
import AddNewCargo from '../../pages/Shipper/Profile/AddNewCargo';
import {
  additionalCharges,
  getInsuranceMarkup,
  getQuotePrice
} from '../../utils/quote.util';
import { BID_TYPE, BID_STATUS } from '../../constants/bids.constants';
import AddFeesPopup from '../AddFeesPopup';
import { useIsInvoiceAuthorizedUser } from '../../constants/invoice.constants';

const UpdateMultipleShipment = memo(({ quote, openCargo }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(0);
  const [cargoId, setCargoId] = useState();
  const [isPopup, setIsPopup] = useState(false);
  const [addFees, setAddFees] = useState('');
  const insuranceMarkup = getInsuranceMarkup(
    quote.is_insurance_required,
    quote.insurance_value
  );

  const { QUOTE_FROM_NET, FIXED_AMOUNT, OPEN_BID } = BID_TYPE;
  const { ASSIGNED_QUOTE, ENROUTE_QUOTE, DELIVERED_QUOTE } = BID_STATUS;

  const isInvoiceAuthorizedUser = useIsInvoiceAuthorizedUser();

  const validationSchema = yup.object({
    pickupInfo: yup.array().of(
      yup.object().shape({
        pickup_address: yup.object().shape({
          company: yup.string().required('Company name is required'),
          address1: yup.string().required('Address Line 1 is required'),
          address2: yup.string(),
          city: yup.string().required('City is required'),
          state: yup.string().required('State is required'),
          country: yup.string().required('Country is required').default('US'),
          zip: yup.string().required('Zip code is required'),
          contactperson: yup.string(),
          reference: yup.string(),
          contactnumber: yup
            .string()
            .matches(/^[0-9]+$/, 'Mobile number must be only digits')
            .required('Mobile number is required')
            .max(11)
            .min(10, 'Mobile number must be at least 10 digits')
        }),
        pickup_special_instructions: yup.string(),
        pickup_date: yup.string().required('Pickup Date is required'),
        pickup_time: yup.string().required('Pickup Time is required'),
        pickup_close_time: yup
          .string()
          .required('Pickup Close Time is required')
      })
    ),
    deliveryInfo: yup.array().of(
      yup.object().shape({
        delivery_address: yup.object().shape({
          company: yup.string().required('Company name is required'),
          address1: yup.string().required('Address Line 1 is required'),
          address2: yup.string(),
          city: yup.string().required('City is required'),
          state: yup.string().required('State is required'),
          country: yup.string().required('Country is required').default('US'),
          zip: yup.string().required('Zip code is required'),
          contactperson: yup.string(),
          reference: yup.string(),
          contactnumber: yup
            .string()
            .matches(/^[0-9]+$/, 'Mobile number must be only digits')
            .required('Mobile number is required')
            .max(11)
            .min(10, 'Mobile number must be at least 10 digits')
        }),
        delivery_special_instructions: yup.string(),
        delivery_date: yup.string().required('Delivery Date is required'),
        delivery_time: yup.string().required('Delivery Time is required'),
        delivery_close_time: yup
          .string()
          .required('Delivery Close Time is required')
      })
    ),
    service_type: yup.mixed().required('Please select a Service Type.'),
    cargos: yup.array().of(
      yup.object({
        goods_condition: yup.mixed().required('Goods Condition is required'),
        goods_type: yup.array().nullable(),
        package_type: yup.mixed().required('Cargo Package type is required'),
        width: yup.number().when('dummy', {
          is: () =>
            quote.service_type === TRUCKLOAD_FLAT ||
            quote.service_type === TRUCKLOAD_VAN,
          then: () =>
            yup
              .number()
              .positive('Width must be a positive number')
              .min(0)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
          otherwise: () =>
            yup
              .number()
              .positive('Width must be a positive number')
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .required('Cargo width is required')
        }),
        length: yup.number().when('dummy', {
          is: () =>
            quote.service_type === TRUCKLOAD_FLAT ||
            quote.service_type === TRUCKLOAD_VAN,
          then: () =>
            yup
              .number()
              .positive('Length must be a positive number')
              .min(0)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
          otherwise: () =>
            yup
              .number()
              .positive('Length must be a positive number')
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .required('Cargo length is required')
        }),
        height: yup.number().when('dummy', {
          is: () =>
            quote.service_type === TRUCKLOAD_FLAT ||
            quote.service_type === TRUCKLOAD_VAN,
          then: () =>
            yup
              .number()
              .positive('Height must be a positive number')
              .min(0)
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              }),
          otherwise: () =>
            yup
              .number()
              .positive('Height must be a positive number')
              .transform((cv, ov) => {
                return ov === '' ? undefined : cv;
              })
              .required('Cargo height is required')
        }),
        weight: yup
          .number()
          .positive('Weight must be a positive number')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Cargo weight is required'),
        desc: yup.string().required('Cargo description is required'),
        quantity: yup
          .number()
          .positive('Quanitity must be positive')
          .transform((cv, ov) => {
            return ov === '' ? undefined : cv;
          })
          .required('Cargo quantity is required')
      })
    ),
    special_instructions: yup.string(),
    bidType: yup.number(),
    estimate: yup.mixed().when('dummy', {
      is: () => quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID,
      then: () => yup.number().nullable(),
      otherwise: () =>
        yup.number().positive().required('Estimate should be empty.')
    }),
    commission: yup.number().nullable(),
    netCharge: yup.number().nullable(),
    driverName: yup.string().nullable(),
    driverPhone: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .matches(/^[0-9]+$/, 'Mobile number must be only digits')
      .max(11)
      .min(10, 'Mobile number must be at least 10 digits')
  });
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const form = watch();

  const onSubmit = async (data) => {
    const updatedData = JSON.parse(JSON.stringify(data));

    // delete updatedData.carrierPrice;
    const { revenueCharge } = additionalCharges(quote);

    if (updatedData.amount) {
      updatedData.amount =
        updatedData.bidType === FIXED_AMOUNT
          ? (
              Number(updatedData.amount) - Number(updatedData.tarps_value)
            )?.toFixed(2)
          : (
              Number(updatedData.amount) -
              Number(updatedData.netCharge) -
              Number(updatedData.tarps_value)
            )?.toFixed(2);
      updatedData.amount -= revenueCharge;
    }

    if (updatedData.estimate && updatedData.bidType === QUOTE_FROM_NET) {
      updatedData.estimate -= updatedData.tarps_value;
      updatedData.estimate -= revenueCharge;
    }
    const newPickupData = updatedData.pickupInfo.map((pick) => {
      const pickCopy = JSON.parse(JSON.stringify(pick));

      pickCopy.pickup_date = formatPickupDeliveryDate(pick.pickup_date, false);
      pickCopy.pickup_time = formatPickupDeliveryTime(pick.pickup_time, false);
      pickCopy.pickup_close_time = !pick.pickup_close_time
        ? ''
        : formatPickupDeliveryTime(pick.pickup_close_time, false);

      return pickCopy;
    });
    const newDeliveryData = updatedData.deliveryInfo.map((del) => {
      const delCopy = JSON.parse(JSON.stringify(del));
      delCopy.delivery_date = formatPickupDeliveryDate(
        del.delivery_date,
        false
      );
      delCopy.delivery_time = formatPickupDeliveryTime(
        del.delivery_time,
        false
      );
      delCopy.delivery_close_time = !del.delivery_close_time
        ? ''
        : formatPickupDeliveryTime(del.delivery_close_time, false);

      return delCopy;
    });
    updatedData.pickupInfo = newPickupData;
    updatedData.deliveryInfo = newDeliveryData;
    const differ = Object.keys(updatedData).reduce((diff, key) => {
      const updatedCargo = quote.cargos.map((cargo) => {
        return {
          ...cargo,
          package_type: cargo?.package_type?.[0].id,
          goods_type: cargo?.goods_type?.map((type) => type.id),
          goods_condition: cargo?.goods_condition?.id
        };
      });
      const quoteData = {
        ...quote,
        cargos: updatedCargo
      };

      if (JSON.stringify(quoteData[key]) === JSON.stringify(updatedData[key]))
        return diff;
      return {
        ...diff,
        [key]: updatedData[key]
      };
    }, {});

    if (!Object.keys(differ).length)
      return toast.error('You have not made any changes!');

    differ.id = quote.id;
    differ.company = quote?.company?.id;

    if (
      (quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID) &&
      (differ?.pickup_address || differ?.delivery_address)
    ) {
      if (!differ?.pickup_address?.address1) {
        delete differ.pickup_address;
      }
      if (!differ?.delivery_address?.address1) {
        delete differ.delivery_address;
      }
    }

    try {
      const response = await updateShipmentService(differ);
      if (response?.data?.flag) {
        window.location.reload();
        return toast.success(response.data.message);
      }
    } catch (error) {
      return toast.error(error.message);
    }
  };

  useEffect(() => {
    if (quote) {
      const updatedCargo = quote.cargos.map((cargo) => {
        return {
          ...cargo,
          package_type: cargo?.package_type?.[0].id,
          goods_type: cargo?.goods_type?.map((type) => type.id),
          goods_condition: cargo?.goods_condition?.id
        };
      });

      let quoteData = {
        ...quote,
        service_type: quote.service_type,
        special_instructions: quote.special_instructions,
        cargos: updatedCargo,
        estimate: quote.estimate,
        tarps_value: quote.tarps_value,
        amount: quote.amount,
        netCharge: quote.netCharge,
        bidType: quote.bidType,
        commission: quote.commission,
        carrier_type: quote.carrier_type,
        tracking_details: quote?.tracking_details,
        driverName: quote?.driverName,
        driverPhone: quote?.driverPhone,
        assignedCarrier: quote?.assignedCarrier,
        phoneNumber: quote?.carrierDetails?.phone,
        email: quote?.carrierDetails?.email,
        shipperName: quote?.company?.name,
        salesRepName: quote?.salesRepName,
        additional_charges: quote?.additional_charges || []
      };

      const { revenueCharge } = additionalCharges(quoteData);
      const { carrierCharge } = additionalCharges(quoteData);

      if (quote.bidType === QUOTE_FROM_NET) {
        quoteData.carrierPrice =
          Number(quote.estimate) -
          Number(quote.commission) +
          Number(quote.tarps_value_carrier) +
          Number(carrierCharge) -
          insuranceMarkup;
        quoteData.estimate =
          quote.estimate + quote.tarps_value + Number(revenueCharge);
      }

      if (quote.bidType === FIXED_AMOUNT || quote.bidType === OPEN_BID) {
        quoteData.carrierPrice =
          Number(quote.amount) -
          Number(quote.netCharge) +
          Number(quote.tarps_value_carrier) +
          Number(carrierCharge) -
          insuranceMarkup;
        quoteData.amount =
          quote.amount + quote.tarps_value + Number(revenueCharge);
      }

      const pickupInfo = quote.pickupInfo?.map((pick) => {
        const pickCopy = JSON.parse(JSON.stringify(pick));

        pickCopy.pickup_date =
          pickCopy.pickup_date !== 'flexible date'
            ? new Date(pickCopy.pickup_date)
            : '';
        pickCopy.pickup_time =
          pickCopy.pickup_time !== 'flexible time'
            ? new Date(moment(pickCopy.pickup_time, 'h:mm a').format())
            : '';
        pickCopy.pickup_close_time = pickCopy.pickup_close_time
          ? new Date(moment(pickCopy.pickup_close_time, 'h:mm a').format())
          : '';
        return pickCopy;
      });
      const deliveryInfo = quote.deliveryInfo?.map((del) => {
        const delCopy = JSON.parse(JSON.stringify(del));

        delCopy.delivery_date =
          delCopy.delivery_date !== 'flexible date'
            ? new Date(delCopy.delivery_date)
            : '';
        delCopy.delivery_time =
          delCopy.delivery_time && delCopy.delivery_time !== 'flexible time'
            ? new Date(moment(delCopy.delivery_time, 'h:mm a').format())
            : '';
        delCopy.delivery_close_time = delCopy.delivery_close_time
          ? new Date(moment(delCopy.delivery_close_time, 'h:mm a').format())
          : '';
        return delCopy;
      });
      quoteData = { ...quoteData, pickupInfo, deliveryInfo };
      reset(quoteData);
      setCargoId(quoteData?.cargos?.[0]?.id);
    }
  }, [quote]);

  useEffect(() => {
    openCargo(cargoId);
  }, [cargoId]);

  function toggleAccordion(value) {
    setOpen(open === value ? 0 : value);
  }

  const removeCharge = (idx) => {
    form.additional_charges?.splice(idx, 1);
    setValue('additional_charges', form.additional_charges);
  };

  const handleOnChange = (e) => {
    if (form.bidType === FIXED_AMOUNT || form.bidType === OPEN_BID) {
      setValue(
        'netCharge',
        Number(form.amount) -
          Number(e.target.value) -
          (Number(quote.tarps_value) - Number(quote.tarps_value_carrier)) -
          insuranceMarkup
      );
    } else {
      setValue(
        'commission',
        Number(form.estimate) -
          Number(e.target.value) -
          (Number(quote.tarps_value) - Number(quote.tarps_value_carrier)) -
          insuranceMarkup
      );
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((item) => {
        if (errors[item].message) {
          toast.error(errors[item].message);
        } else {
          toast.error('Validation Error!');
        }
      });
    }
  }, [errors]);

  return (
    <section className="pt-10">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-5">
          <div className="flex justify-between">
            <h4 className="text-xl font-bold">Service Type</h4>
            <button
              type="button"
              className={`${
                quote.bidStatus === DELIVERED_QUOTE
                  ? 'hidden'
                  : 'inline-block text-navy-500 clear-both'
              }`}
              onClick={() => toggleAccordion(1)}
            >
              <u>Edit</u>
            </button>
          </div>
          <div
            className={
              open === 1
                ? `hidden`
                : `flex justify-start mt-3 overflow-auto transition-max-height duration-10 ease-in-out ml-1`
            }
          >
            {
              SERVICE_TYPES.find(
                (service) => service.value === form.service_type
              )?.label
            }
          </div>

          <div
            className={
              open === 1
                ? `overflow-auto transition-max-height duration-700 ease-in-out`
                : 'hidden'
            }
            style={{ maxHeight: open === 1 ? '50px' : '0px' }}
          >
            <select
              className="flex justify-start mt-3"
              name="service_type"
              {...register('service_type')}
              disabled={[ENROUTE_QUOTE, DELIVERED_QUOTE].includes(
                quote.bidStatus
              )}
            >
              {SERVICE_TYPES.map((option) => (
                <option
                  key={`${option.value}-${option.label}`}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <hr />
        <div className="flex flex-wrap p-4 -mx-3 ">
          {quote.cargos.map((cg, index) => {
            const pickupDataIdx =
              quote?.pickupInfo?.findIndex(
                (pick) => pick.pickup_id === cg.ship_from
              ) || 0;
            const deliveryDataIdx =
              quote?.deliveryInfo?.findIndex(
                (del) => del.delivery_id === cg.ship_to
              ) || 0;
            const pickupData = quote?.pickupInfo?.[pickupDataIdx];
            const deliveryData = quote?.deliveryInfo?.[deliveryDataIdx];
            return (
              <React.Fragment key={cg.id}>
                <div className="flex flex-wrap items-center w-full p-3 my-3 bg-gray-100">
                  <div>
                    <span className="inline-block p-2 mr-6 border lg:mb-0 md:p-4 bg-navy-50 border-navy-50">
                      {cg?.files?.fd ? (
                        <img
                          className="object-contain w-4 h-4 md:w-6 md:h-6"
                          src={`${process.env.REACT_APP_API_URL}/${cg?.files?.fd}`}
                          alt="cargo img"
                        />
                      ) : (
                        <svg
                          className="w-4 h-4 md:w-6 md:h-6"
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                        >
                          <path
                            id="Icon_material-image"
                            data-name="Icon material-image"
                            d="M31.5,28.5V7.5a3.009,3.009,0,0,0-3-3H7.5a3.009,3.009,0,0,0-3,3v21a3.009,3.009,0,0,0,3,3h21A3.009,3.009,0,0,0,31.5,28.5ZM12.75,20.25l3.75,4.515L21.75,18l6.75,9H7.5Z"
                            transform="translate(-4.5 -4.5)"
                            fill="#1d4690"
                          />
                        </svg>
                      )}
                    </span>
                  </div>
                  <div className="text-left">
                    <h2>
                      {cg.quantity} - {cg?.package_type[0]?.display_name}
                    </h2>
                    <h2>
                      {cg.length} in x {cg.width} in x {cg.height} in x{' '}
                      {cg.weight} lbs
                    </h2>
                  </div>
                  <div className="mt-auto mb-auto ml-auto">
                    <button
                      type="button"
                      onClick={() => {
                        setCargoId(cargoId === cg.id ? null : cg.id);
                      }}
                    >
                      {cargoId === cg.id ? (
                        <span className="text-2xl">⌃</span>
                      ) : (
                        <span className="text-4xl">⌄</span>
                      )}
                    </button>
                  </div>
                </div>
                {cg.id === cargoId ? (
                  <>
                    <div className="flex flex-col mt-5 mb-5">
                      <div className="flex justify-between">
                        <h4 className="text-xl font-bold">
                          Pickup information
                        </h4>
                        {!(
                          quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser
                        ) && (
                          <button
                            type="button"
                            className="inline-block clear-both text-navy-500"
                            onClick={() => toggleAccordion(2)}
                          >
                            <u>Edit</u>
                          </button>
                        )}
                      </div>
                      <div
                        className={
                          open === 2 ||
                          errors.pickup_address ||
                          errors.pickupDate ||
                          errors.pickup_close_time
                            ? `hidden`
                            : `flex flex-col justify-start mt-3 overflow-auto transition-max-height duration-700 ease-in-out ml-1 space-y-3`
                        }
                      >
                        <div className="flex flex-col justify-start space-y-1">
                          <h2 className="flex font-semibold">Ship from</h2>
                          {pickupData?.pickup_address ? (
                            <>
                              <p className="flex">{`${pickupData?.pickup_address?.company}`}</p>
                              <p className="flex">{`${
                                pickupData?.pickup_address?.address1
                              }, ${
                                pickupData?.pickup_address?.address2
                                  ? `${pickupData?.pickup_address?.address2},`
                                  : ''
                              } ${pickupData?.pickup_address?.city}, ${
                                pickupData?.pickup_address?.state
                              }, ${pickupData?.pickup_address?.country}, ${
                                pickupData?.pickup_zip_code
                              }`}</p>
                              <p className="flex">{`${pickupData?.pickup_address?.contactperson}, ${pickupData?.pickup_address?.contactnumber}`}</p>
                            </>
                          ) : (
                            <p className="flex">
                              {pickupData?.pickup_zip_code}
                            </p>
                          )}
                        </div>

                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">Pickup date</h2>
                          <p className="flex ">
                            {pickupData?.pickup_date || 'flexible date'}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">Pickup time</h2>
                          <p className="flex ">
                            {pickupData?.pickup_time || 'flexible time'}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">
                            Pickup close time
                          </h2>
                          <p className="flex">
                            {pickupData?.pickup_close_time || 'N/A'}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">Pickup service</h2>
                          <p className="flex ">
                            {pickupData?.pickup_service_options
                              ?.map(
                                (service) =>
                                  PICKUP_SERVICE_OPTION.find(
                                    (ser) => ser.value === service
                                  )?.label
                              )
                              .join(', ')}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">
                            Special pickup instruction
                          </h2>
                          <p className="flex">
                            {pickupData.pickup_special_instructions}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">Shipper Ref#</h2>
                          <p className="flex">
                            {pickupData?.pickup_address?.reference}
                          </p>
                        </div>
                      </div>
                      <div
                        className="overflow-auto duration-700 ease-in-out transition-max-height"
                        style={{
                          maxHeight:
                            open === 2 ||
                            errors.pickup_address ||
                            errors.pickupDate ||
                            errors.pickup_close_time
                              ? `900px`
                              : '0px'
                        }}
                      >
                        <div className="flex flex-col mt-3 overflow-auto duration-700 ease-in-out transition-max-height">
                          <h3 className="flex justify-start text-xl font-bold">
                            Ship from
                          </h3>
                          <div className="clear-both pt-4">
                            <div className="w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Company Name
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.company`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickup_address?.company && (
                                <span className="text-xs text-red-700">
                                  {errors?.pickup_address?.company?.message}
                                </span>
                              )}
                            </div>
                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Address1
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.address1`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0]?.pickup_address
                                ?.address1 && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_address
                                      .address1?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Address2
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.address2`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0]?.pickup_address
                                ?.address2 && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_address
                                      .address2?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                City
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.city`,
                                  {
                                    required: true,
                                    minLength: 5,
                                    maxLength: 5
                                  }
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0]?.pickup_address
                                ?.city && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_address
                                      ?.city?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                State
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.state`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0]?.pickup_address
                                ?.state && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_address
                                      ?.state?.message
                                  }
                                </span>
                              )}
                            </div>
                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Pickup Zip
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_zip_code`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0].pickup_zip_code && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_zip_code
                                      ?.message
                                  }
                                </span>
                              )}
                            </div>
                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Country
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.country`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0]?.pickup_address
                                ?.country && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_address
                                      ?.country?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Contact Name
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.contactperson`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0]?.pickup_address
                                ?.contactperson && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_address
                                      ?.contactperson?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Contact Number
                              </label>
                              <input
                                {...register(
                                  `pickupInfo[${pickupDataIdx}].pickup_address.contactnumber`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.pickupInfo?.[0]?.pickup_address
                                ?.contactnumber && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.pickupInfo?.[0]?.pickup_address
                                      ?.contactnumber?.message
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Pickup date
                          </h3>
                          <Controller
                            render={({
                              field: { ref, name, value, onChange }
                            }) => (
                              <DatePickerInput
                                name={name}
                                ref={ref}
                                handleDateChange={(e) => onChange(e)}
                                selectedDate={value}
                              />
                            )}
                            name={`pickupInfo[${pickupDataIdx}].pickup_date`}
                            control={control}
                          />
                          {errors?.pickupInfo?.[0]?.pickupDate?.type ===
                            'required' && (
                            <p className="flex justify-start text-red-700">
                              pickup date is required
                            </p>
                          )}
                        </div>

                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Pickup time
                          </h3>
                          <Controller
                            render={({
                              field: { ref, name, value, onChange }
                            }) => (
                              <TimePickerInput
                                name={name}
                                ref={ref}
                                handleDateChange={(e) => onChange(e)}
                                selectedDate={value}
                              />
                            )}
                            name={`pickupInfo[${pickupDataIdx}].pickup_time`}
                            control={control}
                          />
                          {errors?.pickupInfo?.[0]?.pickupDate?.type ===
                            'required' && (
                            <p className="flex justify-start text-red-700">
                              pickup time is required
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Pickup close time
                          </h3>
                          <Controller
                            render={({
                              field: { ref, name, value, onChange }
                            }) => (
                              <TimePickerInput
                                name={name}
                                ref={ref}
                                handleDateChange={(e) => onChange(e)}
                                selectedDate={value}
                              />
                            )}
                            name={`pickupInfo[${pickupDataIdx}].pickup_close_time`}
                            control={control}
                          />
                          {errors?.pickupInfo?.[0]?.pickup_close_time && (
                            <p className="flex justify-start text-red-700">
                              {
                                errors?.pickupInfo?.[0]?.pickup_close_time
                                  ?.message
                              }
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col my-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Pickup service
                          </h3>
                          {PICKUP_SERVICE_OPTION.map((pickupOption) => {
                            return (
                              <div
                                key={pickupOption.value}
                                className="flex justify-start"
                              >
                                <label>
                                  <input
                                    {...register(
                                      `pickupInfo[${pickupDataIdx}].pickup_service_options`
                                    )}
                                    type="checkbox"
                                    value={pickupOption.value}
                                  />
                                  <span>{pickupOption.label}</span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Special pickup instruction
                          </h3>
                          <input
                            {...register(
                              `pickupInfo[${pickupDataIdx}].pickup_special_instructions`
                            )}
                            type="text"
                            className="w-full px-2 py-2 mt-2 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                          />
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Shipper Ref#
                          </h3>
                          <input
                            {...register(
                              `pickupInfo[${pickupDataIdx}].pickup_address.reference`
                            )}
                            type="text"
                            className="w-full px-2 py-2 my-2 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="flex flex-col mt-5 mb-5">
                      <div className="flex justify-between">
                        <h4 className="text-xl font-bold">
                          Delivery information
                        </h4>
                        {!(
                          quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser
                        ) && (
                          <button
                            type="button"
                            className="inline-block clear-both text-navy-500"
                            onClick={() => toggleAccordion(3)}
                          >
                            <u>Edit</u>
                          </button>
                        )}
                      </div>
                      <div
                        className={
                          open === 3 ||
                          errors.delivery_address ||
                          errors.deliveryDate ||
                          errors.delivery_close_time
                            ? `hidden`
                            : `flex flex-col justify-start mt-3 overflow-auto transition-max-height duration-700 ease-in-out ml-1 space-y-3`
                        }
                      >
                        <div className="flex flex-col justify-start space-y-1">
                          <h2 className="flex font-semibold">Ship to</h2>
                          {deliveryData.delivery_address ? (
                            <>
                              {' '}
                              <p className="flex">{`${deliveryData?.delivery_address?.company}`}</p>
                              <p className="flex">{`${
                                deliveryData?.delivery_address?.address1
                              }, ${
                                deliveryData?.delivery_address?.address2
                                  ? `${deliveryData?.delivery_address?.address2},`
                                  : ''
                              } ${deliveryData?.delivery_address?.city}, ${
                                deliveryData?.delivery_address?.state
                              }, ${
                                deliveryData?.delivery_address?.country
                              }, ${deliveryData?.delivery_zip_code}`}</p>
                              <p className="flex">{`${deliveryData?.delivery_address?.contactperson}, ${deliveryData?.delivery_address?.contactnumber}`}</p>
                            </>
                          ) : (
                            <p className="flex">
                              {deliveryData.delivery_zip_code}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">Delivery date</h2>
                          <p className="flex ">
                            {deliveryData?.delivery_date || 'flexible time'}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">Delivery time</h2>
                          <p className="flex ">
                            {deliveryData?.delivery_time || 'flexible time'}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">
                            Delivery close time
                          </h2>
                          <p className="flex ">
                            {deliveryData.delivery_close_time || 'N/A'}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">
                            Delivery service
                          </h2>
                          <p className="flex">
                            {deliveryData?.delivery_service_options
                              ?.map(
                                (service) =>
                                  DELIVERY_SERVICE_OPTION.find(
                                    (ser) => ser.value === service
                                  )?.label
                              )
                              .join(', ')}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">
                            Special delivery instruction
                          </h2>
                          <p className="flex">
                            {deliveryData.delivery_special_instructions}
                          </p>
                        </div>
                        <div className="flex flex-col justify-start">
                          <h2 className="flex font-semibold">Consignee Ref#</h2>
                          <p className="flex">
                            {deliveryData?.delivery_address?.reference}
                          </p>
                        </div>
                      </div>
                      <div
                        className="overflow-auto duration-700 ease-in-out transition-max-height"
                        style={{
                          maxHeight:
                            open === 3 ||
                            errors.delivery_address ||
                            errors.deliveryDate ||
                            errors.delivery_close_time
                              ? `900px`
                              : '0px'
                        }}
                      >
                        <div className="flex flex-col mt-3 overflow-auto duration-700 ease-in-out transition-max-height">
                          <h3 className="flex justify-start text-xl font-bold">
                            Ship to
                          </h3>
                          <div className="clear-both pt-4">
                            <div className="w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Company Name
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.company`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.company && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      ?.company?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Address1
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.address1`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.address1 && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      .address1?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Address2
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.address2`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.address2 && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      .address2?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                City
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.city`,
                                  {
                                    required: true,
                                    minLength: 5,
                                    maxLength: 5
                                  }
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.city && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      ?.city?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                State
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.state`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.state && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      ?.state?.message
                                  }
                                </span>
                              )}
                            </div>
                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Delivery Zip
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_zip_code`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0].delivery_zip_code && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_zip_code
                                      ?.message
                                  }
                                </span>
                              )}
                            </div>
                            <div className="float-left w-1/4 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Country
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.country`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.country && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      ?.country?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Contact Name
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.contactperson`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.contactperson && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      ?.contactperson?.message
                                  }
                                </span>
                              )}
                            </div>

                            <div className="float-left w-1/2 pr-1 mb-4">
                              <label
                                className="block w-full mb-2 font-bold text-black"
                                htmlFor="ship-from"
                              >
                                Contact Number
                              </label>
                              <input
                                {...register(
                                  `deliveryInfo[${deliveryDataIdx}].delivery_address.contactnumber`
                                )}
                                className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              />
                              {errors?.deliveryInfo?.[0]?.delivery_address
                                ?.contactnumber && (
                                <span className="text-xs text-red-700">
                                  {
                                    errors?.deliveryInfo?.[0]?.delivery_address
                                      ?.contactnumber?.message
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Delivery date
                          </h3>
                          <Controller
                            render={({
                              field: { ref, name, value, onChange }
                            }) => (
                              <DatePickerInput
                                name={name}
                                ref={ref}
                                handleDateChange={(e) => onChange(e)}
                                selectedDate={value}
                              />
                            )}
                            name={`deliveryInfo[${deliveryDataIdx}].delivery_date`}
                            control={control}
                          />
                          {errors?.deliveryInfo?.[0]?.deliveryDate?.type ===
                            'required' && (
                            <p className="flex justify-start text-red-700">
                              Delivery date is required
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Delivery time
                          </h3>
                          <Controller
                            render={({
                              field: { ref, name, value, onChange }
                            }) => (
                              <TimePickerInput
                                name={name}
                                ref={ref}
                                handleDateChange={(e) => onChange(e)}
                                selectedDate={value}
                              />
                            )}
                            name={`deliveryInfo[${deliveryDataIdx}].delivery_time`}
                            control={control}
                          />
                          {errors?.deliveryInfo?.[0]?.deliveryTime?.type ===
                            'required' && (
                            <p className="flex justify-start text-red-700">
                              Delivery Time is required
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Delivery close time
                          </h3>
                          <Controller
                            render={({
                              field: { ref, name, value, onChange }
                            }) => (
                              <TimePickerInput
                                name={name}
                                ref={ref}
                                handleDateChange={(e) => onChange(e)}
                                selectedDate={value}
                              />
                            )}
                            name={`deliveryInfo[${deliveryDataIdx}].delivery_close_time`}
                            control={control}
                          />
                          {errors?.deliveryInfo?.[0]?.delivery_close_time && (
                            <p className="flex justify-start text-red-700">
                              {errors?.delivery_close_time?.message}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Delivery service
                          </h3>
                          {DELIVERY_SERVICE_OPTION.map((deliveryOption) => {
                            return (
                              <div
                                key={deliveryOption.value}
                                className="flex justify-start"
                              >
                                <label>
                                  <input
                                    {...register(
                                      `deliveryInfo[${deliveryDataIdx}].delivery_service_options`
                                    )}
                                    type="checkbox"
                                    value={deliveryOption.value}
                                  />
                                  <span>{deliveryOption.label}</span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Special Delivery instruction
                          </h3>
                          <input
                            {...register(
                              `deliveryInfo[${deliveryDataIdx}].delivery_special_instructions`
                            )}
                            className="w-full px-2 py-2 mt-2 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                          />
                        </div>
                        <div className="flex flex-col mt-3">
                          <h3 className="flex justify-start text-xl font-bold">
                            Consignee Ref#
                          </h3>
                          <input
                            {...register(
                              `deliveryInfo[${deliveryDataIdx}].delivery_address.reference`
                            )}
                            type="text"
                            className="w-full px-2 py-2 my-2 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="flex flex-col mt-5 mb-5">
                      <div className="flex justify-between">
                        <h4 className="text-xl font-bold">Cargo details</h4>
                        {!quote?.isInvoiceGenerated && (
                          <button
                            type="button"
                            className="inline-block clear-both text-navy-500"
                            onClick={() => toggleAccordion(4)}
                          >
                            <u>Edit</u>
                          </button>
                        )}
                      </div>
                      <div
                        className={
                          open === 4 || errors.cargos
                            ? `hidden`
                            : `flex flex-col justify-start mt-3 overflow-auto transition-max-height duration-700 ease-in-out ml-1 space-y-3`
                        }
                      >
                        <div className="pb-5 mt-1 border border-t-0 border-l-0 border-r-0 border-gray-200">
                          <CargoDetailsCard cargo={cg} />
                        </div>
                      </div>
                      <div
                        className="overflow-auto duration-700 ease-in-out transition-max-height"
                        style={{
                          maxHeight:
                            open === 4 || errors.cargos ? `900px` : '0px'
                        }}
                      >
                        <div className="clear-both p-4 pt-5 focus-within:border-gray-200">
                          <h3 className="block mb-6 text-xl text-left">{`${cg.quantity} - ${cg.desc}`}</h3>
                          <div className="grid float-left grid-cols-4 mb-4">
                            {PACKAGE_TYPE.map((packageType) => {
                              return (
                                <div className="w-1/4">
                                  <label>
                                    <input
                                      {...register(
                                        `cargos[${index}].package_type`
                                      )}
                                      type="radio"
                                      value={packageType.value}
                                    />
                                    <span className="ml-1">
                                      {packageType.label}
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                          <div className="float-left w-1/4 pr-1 mb-4">
                            <label
                              className="block w-full mb-2 text-sm font-bold text-black"
                              htmlFor="ship-from"
                            >
                              Length
                            </label>
                            <input
                              {...register(`cargos[${index}].length`)}
                              className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              // min="0"
                            />
                            {errors.cargos?.[index]?.length && (
                              <span className="text-xs text-red-700">
                                {errors.cargos?.[index]?.length?.message}
                              </span>
                            )}
                          </div>

                          <div className="float-left w-1/4 pr-1 mb-4">
                            <label
                              className="block w-full mb-2 text-sm font-bold text-black"
                              htmlFor="ship-from"
                            >
                              Width
                            </label>
                            <input
                              {...register(`cargos[${index}].width`)}
                              className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              // min="0"
                            />
                            {errors.cargos?.[index]?.width && (
                              <span className="text-xs text-red-700">
                                {errors.cargos?.[index]?.width?.message}
                              </span>
                            )}
                          </div>

                          <div className="float-left w-1/4 pr-1 mb-4">
                            <label
                              className="block w-full mb-2 text-sm font-bold text-black"
                              htmlFor="ship-from"
                            >
                              Height
                            </label>
                            <input
                              {...register(`cargos[${index}].height`)}
                              className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              // min="0"
                            />
                            {errors.cargos?.[index]?.height && (
                              <span className="text-xs text-red-700">
                                {errors.cargos?.[index]?.height?.message}
                              </span>
                            )}
                          </div>

                          <div className="float-left w-1/4 pr-1 mb-4">
                            <label
                              className="block w-full mb-2 text-sm font-bold text-black"
                              htmlFor="ship-from"
                            >
                              Weight
                            </label>
                            <input
                              {...register(`cargos[${index}].weight`)}
                              className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              min="0"
                            />
                            {errors.cargos?.[index]?.weight && (
                              <span className="text-xs text-red-700">
                                {errors.cargos?.[index]?.weight?.message}
                              </span>
                            )}
                          </div>
                          <div className="clear-both text-sm font-bold">
                            <div className="float-left w-1/2">
                              <p className="w-full mb-3 text-left text-black">
                                Goods Condition
                              </p>
                              <div className="flex w-1/2 mb-3">
                                <select
                                  {...register(
                                    `cargos[${index}].goods_condition`
                                  )}
                                  className="flex w-full px-2 py-2 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                                >
                                  {GOODS_CONDITION.map((option) => (
                                    <option
                                      value={option.value}
                                      className="w-full text-xs font-semibold leading-none bg-white border-gray-200 outline-none appearance-none"
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                                {errors.cargos?.[index]?.goods_condition && (
                                  <span className="text-xs text-red-700">
                                    {
                                      errors.cargos?.[index]?.goods_condition
                                        ?.message
                                    }
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="float-left w-1/2">
                              <p className="w-full mb-3 text-left text-black">
                                Quantity
                              </p>
                              <div className="flex w-1/2 mb-3">
                                <input
                                  {...register(`cargos[${index}].quantity`)}
                                  className="flex w-full px-2 py-2 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                                  type="number"
                                  onWheel={(e) => e.target.blur()}
                                  min="0"
                                />
                                {errors.cargos?.[index]?.quantity && (
                                  <span className="text-xs text-red-700">
                                    {errors.cargos?.[index]?.quantity?.message}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="clear-both pt-6 space-x-4 text-sm">
                            {GOODS_TYPE.map((goodsType) => {
                              return (
                                <div className="float-left">
                                  <label>
                                    <input
                                      {...register(
                                        `cargos[${index}].goods_type`
                                      )}
                                      type="checkbox"
                                      value={goodsType.value}
                                    />
                                    <span className="ml-1">
                                      {goodsType.label}
                                    </span>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                          <div className="clear-both pt-5">
                            <textarea
                              {...register(`cargos[${index}].desc`)}
                              className="w-full border resize"
                              placeholder="make, model, serial number"
                            />
                            {errors.cargos?.[index]?.desc && (
                              <span className="text-xs text-red-700">
                                {errors.cargos?.[index]?.desc?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </React.Fragment>
            );
          })}
          <div className="w-full pb-5 mt-6">
            <button
              type="button"
              className={`float-left inline-block w-1/3 py-2 text-sm font-bold text-center text-white ${quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser ? 'bg-gray-400 cursor-not-allowed' : 'transition duration-200 border bg-navy-500 border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700'}`}
              onClick={() => setIsPopup(true)}
              disabled={quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser}
            >
              Add New Cargo
            </button>
          </div>
        </div>
        {isPopup && (
          <div className="fixed inset-0 z-10 overflow-y-auto backdrop-filter backdrop-brightness-50">
            <div className="flex items-center px-4 py-8">
              <div className="relative w-full py-8 bg-white rounded-md shadow-lg">
                <AddNewCargo
                  quote={quote}
                  isPopup={isPopup}
                  setIsPopup={(data) => setIsPopup(data)}
                />
              </div>
            </div>
          </div>
        )}
        <hr />
        <div className="my-5">
          <div className="flex justify-between">
            <h4 className="text-xl font-bold">Billing Details</h4>
            {!(quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser) && (
              <button
                type="button"
                className="inline-block clear-both text-navy-500"
                onClick={() => toggleAccordion(1)}
              >
                <u>Edit</u>
              </button>
            )}
          </div>

          <div
            className={
              open === 1
                ? `hidden`
                : `flex justify-start mt-3 overflow-auto transition-max-height duration-10 ease-in-out ml-1 flex-col space-y-3`
            }
          >
            <div className="flex flex-col justify-start">
              <h2 className="flex font-semibold">Shipment Revenue</h2>
              <p className="flex">{getQuotePrice(quote)}</p>
            </div>
            <div className="flex flex-col justify-start">
              <h2 className="flex font-semibold">Carrier Price</h2>
              <p className="flex">
                {' '}
                <span>
                  {' '}
                  {form?.carrierPrice
                    ? `$ ${Number(form.carrierPrice).toFixed(2)}`
                    : ''}
                </span>
              </p>
            </div>
            {quote?.is_insurance_required && (
              <div className="flex justify-start flex-col">
                <h2 className="flex font-semibold">Insurance Price</h2>
                <p className="flex">$ {insuranceMarkup}</p>
              </div>
            )}
            <div className="flex flex-col justify-start">
              <h2 className="flex font-semibold">Shipment Profit</h2>
              <p className="flex">
                {' '}
                {(quote.bidType === FIXED_AMOUNT ||
                  quote.bidType === OPEN_BID) &&
                quote.amount ? (
                  <div>
                    {form.amount ? (
                      <>
                        ${' '}
                        {(
                          Number(form.amount) -
                          Number(form.carrierPrice) -
                          insuranceMarkup
                        )?.toFixed(2)}
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  <>
                    ${' '}
                    {(
                      Number(form.estimate) -
                      Number(form.carrierPrice) -
                      insuranceMarkup
                    )?.toFixed(2)}
                  </>
                )}
              </p>
            </div>
            <div className="flex flex-col justify-start">
              <h2 className="flex font-semibold">Shipper Name</h2>
              <p className="flex">{form.shipperName}</p>
            </div>
            <div className="flex flex-col justify-start">
              <h2 className="flex font-semibold">SalesRep Name</h2>
              <p className="flex">{form.salesRepName}</p>
            </div>
            {quote?.additional_charges?.length ? (
              <div className="flex flex-col justify-start">
                <h2 className="flex font-semibold">Additional Charges</h2>
                <p className="flex flex-col items-start justify-start">
                  {quote?.additional_charges?.map((charge) => {
                    return (
                      <p className="capitalize">
                        {charge.feesType} -{' '}
                        {
                          ADDITIONAL_FEES?.find(
                            (fee) => fee.value === charge.name
                          )?.label
                        }{' '}
                        ($ {charge.fees})
                      </p>
                    );
                  })}
                </p>
              </div>
            ) : null}
            {[ENROUTE_QUOTE, DELIVERED_QUOTE].includes(quote.bidStatus) ? (
              <>
                <div className="flex flex-col justify-start">
                  <h2 className="flex font-semibold">Assigned To</h2>
                  <p className="flex">{form?.assignedCarrier}</p>
                </div>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Carrier Phone Number</h2>
                  <p className="flex">
                    <a href={`tel:${form?.phoneNumber}`}>{form?.phoneNumber}</a>
                  </p>
                </div>
                <div className="flex justify-start flex-col">
                  <h2 className="flex font-semibold">Carrier Email</h2>
                  <p className="flex">
                    <a href={`mailto:${form?.email}`}>{form?.email}</a>
                  </p>
                </div>
                <div className="flex flex-col justify-start">
                  <h2 className="flex font-semibold">Driver Name</h2>
                  <p className="flex">{form.driverName}</p>
                </div>
                <div className="flex flex-col justify-start">
                  <h2 className="flex font-semibold">Driver Phone</h2>
                  <p className="flex">
                    <a href={`tel:${form?.driverPhone}`}>{form?.driverPhone}</a>
                  </p>
                </div>
              </>
            ) : (
              ''
            )}
          </div>

          <div
            className={
              open === 1
                ? `overflow-auto transition-max-height duration-700 ease-in-out`
                : 'hidden'
            }
            style={{ maxHeight: open === 1 ? '700px' : '0px' }}
          >
            {quote.bidType !== OPEN_BID ||
            quote.bidStatus === ENROUTE_QUOTE ||
            quote.bidStatus === DELIVERED_QUOTE ? (
              <>
                <div className="flex flex-col mt-3 overflow-auto duration-700 ease-in-out transition-max-height">
                  <h3 className="flex justify-start text-xl font-bold">
                    Shipment Revenue
                  </h3>
                  <span className="flex justify-start">
                    <span className="py-2"> $ </span>
                    <input
                      {...register(
                        (quote.bidType === FIXED_AMOUNT ||
                          quote.bidType === OPEN_BID) &&
                          quote.amount
                          ? `amount`
                          : 'estimate'
                      )}
                      className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                      onChange={(e) =>
                        setValue(
                          quote.bidType === FIXED_AMOUNT ||
                            quote.bidType === OPEN_BID
                            ? 'netCharge'
                            : 'commission',
                          Number(e.target.value) -
                            Number(form.carrierPrice) -
                            (Number(quote.tarps_value) -
                              Number(quote.tarps_value_carrier)) -
                            insuranceMarkup
                        )
                      }
                    />
                    <span className="w-1/2 ml-4 text-white bg-navy-500 ">
                      {' '}
                      <button
                        type="button"
                        className="flex items-center justify-center gap-2 px-2 text-xs"
                        onClick={() => setAddFees('revenue')}
                      >
                        <span className="mb-1 text-2xl">+</span> Add New
                        Fees/Charges
                      </button>
                    </span>
                  </span>
                </div>
                <div className="flex flex-col mt-3 overflow-auto duration-700 ease-in-out transition-max-height">
                  <h3 className="flex justify-start text-xl font-bold">
                    Carrier Price
                  </h3>
                  <span className="flex justify-start">
                    <span className="py-2"> $ </span>
                    <input
                      {...register('carrierPrice', {
                        required: true
                      })}
                      className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                      onChange={handleOnChange}
                    />
                    <span className="w-1/2 ml-4 text-white bg-navy-500 ">
                      {' '}
                      <button
                        type="button"
                        className="flex items-center justify-center gap-2 px-2 text-xs"
                        onClick={() => setAddFees('carrier')}
                      >
                        <span className="pb-1 text-2xl">+</span> Add New
                        Fees/Charges
                      </button>
                    </span>
                  </span>
                </div>
                {quote?.additional_charges?.length ? (
                  <div className="flex flex-col mt-3 overflow-auto duration-700 ease-in-out transition-max-height">
                    <h3 className="flex justify-start text-xl font-bold">
                      Additional Charges
                    </h3>

                    {form.additional_charges?.map((charge, index) => {
                      return (
                        <span className="flex flex-col justify-start mt-2">
                          <div className="flex items-center">
                            <span className="py-2"> $ </span>
                            <input
                              {...register(`additional_charges[${index}].fees`)}
                              className="w-full px-2 py-3 text-xs leading-tight bg-white border border-gray-200 border-solid shadow appearance-none"
                            />
                            <span className="w-2/3 ml-2 text-xs text-left capitalize">
                              {
                                ADDITIONAL_FEES?.find(
                                  (e) => e.value === charge.name
                                )?.label
                              }{' '}
                              - <b>{charge.feesType}</b>
                            </span>
                            <button
                              type="button"
                              onClick={() => removeCharge(index)}
                            >
                              <MdDelete
                                color="#d22027"
                                className="w-5 h-5 p-1 bg-red-200 rounded-sm shadow-md "
                              />
                            </button>
                          </div>
                        </span>
                      );
                    })}
                  </div>
                ) : null}
              </>
            ) : null}

            {quote.bidStatus === ASSIGNED_QUOTE ? (
              <>
                <div className="flex flex-col mt-3 overflow-auto duration-700 ease-in-out transition-max-height">
                  <h3 className="flex justify-start text-xl font-bold">
                    Driver Name
                  </h3>
                  <span className="flex justify-start">
                    <input
                      {...register(`driverName`)}
                      type="text"
                      className="pl-2"
                    />
                  </span>
                  {errors.driverName && (
                    <p className="flex justify-start text-red-700">
                      {errors?.driverName?.message}
                    </p>
                  )}
                </div>
                <div className="flex flex-col mt-3">
                  <h3 className="flex justify-start text-xl font-bold">
                    Driver Phone
                  </h3>
                  <input
                    {...register(`driverPhone`)}
                    type="text"
                    className="pl-2"
                  />
                  {errors.driverPhone && (
                    <p className="flex justify-start text-red-700">
                      {errors?.driverPhone?.message}
                    </p>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <hr className="my-5" />
        {addFees ? (
          <AddFeesPopup
            quote={quote}
            feesType={addFees}
            setFeesType={setAddFees}
          />
        ) : null}
        <div className="mt-5 mb-5">
          <div
            className={
              open === 1
                ? `overflow-auto transition-max-height duration-700 ease-in-out`
                : 'hidden'
            }
            style={{ maxHeight: open === 1 ? '300px' : '0px' }}
          >
            <div className="flex flex-col mt-3">
              <h3 className="flex justify-start text-xl font-bold">
                Special instruction
              </h3>
              <input
                {...register('special_instructions')}
                name="special_instructions"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <button
            type="button"
            onClick={() => navigate(`/new-quote?quoteId=${quote.id}`)}
            className="flex flex-row justify-between items-center py-3 px-4 w-1/2
                            bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 text-white 
                            hover:border-navy-600 active:border-navy-700
                              text-sm font-bold transition duration-200 text-left"
          >
            Duplicate
            <RightArrow className="w-5 h-5 font-bold" />
          </button>
          <button
            type="submit"
            className={`flex flex-row justify-between items-center py-3 px-4 w-1/2 text-white text-sm font-bold
            ${quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser ? 'bg-gray-400 cursor-not-allowed' : 'bg-navy-500 border border-navy-500 hover:bg-navy-600 active:bg-navy-700 hover:border-navy-600 active:border-navy-700 transition duration-200'}`}
            disabled={quote?.isInvoiceGenerated && !isInvoiceAuthorizedUser}
          >
            Update
            <RightArrow className="w-5 h-5 font-bold" />
          </button>
        </div>
      </form>
    </section>
  );
});

export default UpdateMultipleShipment;

UpdateMultipleShipment.defaultProps = {
  updatedQuote: null
};

UpdateMultipleShipment.propTypes = {
  quote: PropTypes.object.isRequired,
  openCargo: PropTypes.any.isRequired,
  updatedQuote: PropTypes.object
};
