import React, { useContext } from 'react';
import { AiFillThunderbolt } from 'react-icons/ai';
import { LuFileText } from 'react-icons/lu';
import { GlobalFormContext } from '../../../contexts/QuickQuoteContext';
import { VIEW_TYPE } from '../../../constants/generalConstants';

const BookingMode = () => {
  const { viewType, setViewType, isQuickQuoteEnabled } =
    useContext(GlobalFormContext);
  const { QUICK_VIEW, GUIDED_VIEW } = VIEW_TYPE;
  return (
    <div className="flex flex-row items-center gap-3">
      <span className="font-semibold">View Type:</span>
      <div className="flex flex-row gap-2">
        <button
          type="button"
          disabled={!isQuickQuoteEnabled}
          className={`flex flex-row items-center border px-2 py-1 rounded-sm gap-1 ${viewType === QUICK_VIEW ? 'border-navy-500  bg-navy-20 text-navy-500' : 'text-gray-500 border-gray-300'} ${isQuickQuoteEnabled ? 'cursor-pointer' : 'cursor-not-allowed'}`}
          onClick={() => setViewType(QUICK_VIEW)}
        >
          <AiFillThunderbolt className="text-lg" />
          Quick View
        </button>
        <button
          type="button"
          className={`flex flex-row items-center border px-2 py-1 rounded-sm gap-1 ${viewType === GUIDED_VIEW ? 'border-navy-500  bg-navy-20 text-navy-500' : 'text-gray-500 border-gray-300'}`}
          onClick={() => setViewType(GUIDED_VIEW)}
        >
          <LuFileText className="text-lg" />
          Guided View
        </button>
      </div>
    </div>
  );
};

export default BookingMode;
